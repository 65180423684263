import { Box } from "@mui/material";
import Education from "./components/Education";
import Skills from "./components/Skill";
import "assets/scss/personal-template2.scss";
import Language from "./components/Language";
import Experience from "./components/WorkExperience";
import Hobby from "./components/Hobby";
import Header from "./components/Header";

const Template2 = () => {
  return (
    <Box className="persional-template2">

      <Header />
      <Education />
      <Skills />
      <Experience />
      <Language />
      <Hobby />
    </Box >
  );
};
export default Template2;