/* eslint-disable max-len */
import { Box } from "@mui/system";
import React from "react";
import { coverStyles } from "assets/js/personal-template4";
import Logo from "assets/images/OnlyLogo.png";
import { getGapBetweenCurvedBackgroundMaxWidthAndScreenWidth } from "utils/personal-template4/ShapeBackground";

const ShapeBackground = () => {
  // const theme = useTheme();
  return (
    <Box sx={coverStyles().shapeBackground}>
      <Box sx={{
        position: "absolute",
        left: "2rem",
        top: `calc(4rem + ${getGapBetweenCurvedBackgroundMaxWidthAndScreenWidth()}px)`,
      }}>
        <img src={Logo} />
      </Box>
      <svg xmlns="http://www.w3.org/2000/svg" width="100vw" height="1245" viewBox="0 0 436 1245" fill="none">
        <path d="M436 162.5C268.909 247.709 193.162 27.6142 0 0V1244.5H436V162.5Z" fill="url(#paint0_linear_1053_79)" />
        <defs>
          <linearGradient id="paint0_linear_1053_79" x1="206" y1="630.314" x2="206" y2="105.243" gradientUnits="userSpaceOnUse">
            <stop stop-color="#C4C4C4" />
            <stop offset="1" stop-color="#F2F3F5" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default ShapeBackground; 