import { Box, Typography } from "@mui/material";
import React from "react";
import LineDivider from "./LineDivider";

const Website = () => {
  return (
    <Box sx={{
      "@media (min-height: 800px)": {
        display: "none"
      }
    }}>
      <LineDivider />
      <Typography color={"#F5F5F5"}>www.websitename123.com.vn</Typography>
    </Box>
  );
};

export default Website;