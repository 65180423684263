import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const Skill = ({ skill, percent }) => {
  return (
    <Box sx={{ alignItems: "center", marginTop: "10px" }}>
      <Typography variant="subtitle1">{skill}</Typography>
      <LinearProgress variant="determinate" value={percent} sx={{ flex: 1, marginLeft: "0.5rem" }} />
    </Box>
  );
};

export default Skill;