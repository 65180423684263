import { Box } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ItemContact from "./ItemContact";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import FacebookIcon from "assets/images/Facebook.png";
import TiktokIcon from "assets/images/TikTok.png";
import LinkedIcon from "assets/images/LinkedIn.png";
import IntagramIcon from "assets/images/Instagram Old.png";

const Contact = () => {
  return (
    <>
      <Box className="contact">
        <Box className="contact-item">
          <ItemContact
            icon={<MailOutlineIcon />}
            content="thonglt@lagomvietnam.org"
          />
        </Box>
        <Box className="contact-item">
          <ItemContact
            icon={<PhoneIcon />}
            content="+84 916 68 60 68"
          />
        </Box>
        <Box className="contact-item">
          <ItemContact
            icon={<LocationOnIcon />}
            content="38 Nguyễn Huệ, District 1, HCMC, VN"
          />
        </Box>
        <Box className="contact-item">
          <ItemContact
            icon={<TravelExploreIcon />}
            content="lagomvietnam.org"
          />
        </Box>
        <Box className="contact-icon">
          <a href="https://facebook.com/lagomvietnam.org" target="_blank" rel="noopener noreferrer">
            <img src={FacebookIcon} alt="Icon" />
          </a>
          <a href="http://404" target="_blank" rel="noopener noreferrer">
            <img src={TiktokIcon} alt="Icon" />
          </a>
          <a href="https://www.linkedin.com/company/lagom-vietnam" target="_blank" rel="noopener noreferrer">
            <img src={LinkedIcon} alt="Icon" />
          </a>
          <a href="http://404" target="_blank" rel="noopener noreferrer">
            <img src={IntagramIcon} alt="Icon" />
          </a>
          {/* <a href="http://huy" target="_blank" >
            <TravelExploreIcon />
          </a> */}
        </Box>
      </Box>
    </>
  );
};
export default Contact;