export default function Paper() {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "#000"
        }
      }
    }
  };
}