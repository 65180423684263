import React from "react";
import { Box } from "@mui/material";
import Time from "./Time";
import SubTitleText from "../text/SubTitleText";

const TimeLine = ({ title, subTitle, location, time, marginBottomBase, children }) => {
  return (
    <Box sx={{ marginBottom: marginBottomBase && "1rem" }}>
      <Box className="title-infomation"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <SubTitleText className="title-name">
          {title}
        </SubTitleText>
        <Time time={time} />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }} className="infomation">
        <SubTitleText small className="sub-title">
          {subTitle}
        </SubTitleText>
        <Box sx={{ display: "flex" }} className="location">
          <SubTitleText small margin="0 0.4rem">
            |
          </SubTitleText>
          <SubTitleText small>
            {location}
          </SubTitleText>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default TimeLine;