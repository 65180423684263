/* eslint-disable max-len */
import { Box } from "@mui/material";
import React from "react";
import TitleText from "./text/TitleText";

const Section = ({ title, children }) => {
  return (
    <Box sx={{ padding: "1rem 0" }}>
      <TitleText>{title}</TitleText>
      <Box sx={{ height: "2px", background: "var(--primary-color)", width: "1rem", margin: "0.8rem 0" }} />
      {children}
    </Box>
  );
};

export default Section;