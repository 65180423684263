import { Box } from "@mui/system";

const ContentHeader = () => {
  return (
    <>
      <Box style={{
        textAlign: "center"
      }}>
        <div style={{
          width: "40%", display: "inline-block",
          fontSize: "20px"
        }}>
          <p style={{ fontSize: "30px" }}>Florian
            ARGAUD</p>
          <p style={{ color: "#4FC3F7" }}>
            Hello there, I’m a
            <span style={{ color: "black" }}>  Fullstack dev </span>

            Based in France,
            Guillestre (05)
          </p>
        </div>

      </Box >
    </>
  );
};
export default ContentHeader;