/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../components/NameContentHeader.js";
import ContentEducation from "./ContentEducation.js";
import icon from "assets/images/icons8-education-50.png";

const listContent = [
  {
    title: "Hanoi University of Technology, Hanoi, Viet Nam",
    content: "Electrical and Electrical engineer."
  },
  {
    title: "Self-study",
    content: "Anthropology & human resources, Buddhism, Oriental philosophy, psychology, and management."
  }
];

const Education = ({ background }) => {
  return (
    <>
      <Box className="edutation" style={{ background: background }}>
        <NameContentHeader text="Education" icon={icon} />
        <ContentEducation listContent={listContent} />
      </Box>

    </>
  );
};
export default Education;