import { Box } from "@mui/material";
import React from "react";
import ContentText from "../../../components/text/ContentText";
import TitleText from "../../../components/text/TitleText";
const ListItemWithIcon = ({ title, content, icon, marginBottomBase, ...props }) => {
  const titleParts = typeof title === "string" && title.split(" "); // Tách title thành mảng các phần

  const getNormalTitle = () => {
    try {
      return titleParts[0];
    } catch (e) {
      return "";
    }
  };

  const getHiglightTitle = () => {
    try {
      return titleParts[1];
    } catch (e) {
      return "";
    }
  };

  return (
    <Box display={"flex"} alignItems={"center"} marginBottom={marginBottomBase && "24px"} {...props}>
      {icon}
      <Box marginLeft={"1rem"}>
        <Box display={"flex"}>
          <TitleText>{getNormalTitle()} <TitleText
            component="span"
            color="var(--bussiness-template-2-primary-color)">
            {getHiglightTitle()}
          </TitleText>
          </TitleText>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          {Array.isArray(content) && content.map((text) => {
            return <ContentText marginTop={"0.6rem"}>{text}</ContentText>;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ListItemWithIcon;