import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import image1 from "assets/images/persional_ThongTrungLe/lagon1.jpg";
import image2 from "assets/images/persional_ThongTrungLe/lagon2.jpg";
import image3 from "assets/images/persional_ThongTrungLe/lagon3.jpg";
import image4 from "assets/images/persional_ThongTrungLe/lagon4.jpg";

const steps = [
  {
    label: "Select campaign settings",
    description: `Lagom Vietnam was established in early 2019 as an environmental 
    services entrepreneur, we provide solid waste management services including waste 
    collection & operation improvement, targeting low-value waste such as Used Beverage 
    Carton (UBC) - eg: Milk carton/juice packaging. We have started building our services 
    and products to help businesses and local authorities to 
    eliminate the impacts of low-value waste on the environment via the circular economy concept.`,
    img: image1
  },
  {
    label: "Create an ad group",
    description: `Lagom Vietnam was established in early 2019 as an environmental 
    services entrepreneur, we provide solid waste management services including waste 
    collection & operation improvement, targeting low-value waste such as Used Beverage 
    Carton (UBC) - eg: Milk carton/juice packaging. We have started building our services 
    and products to help businesses and local authorities to 
    eliminate the impacts of low-value waste on the environment via the circular economy concept.`,
    img: image2
  },
  {
    label: "Create an ad",
    description: `Lagom Vietnam was established in early 2019 as an environmental 
    services entrepreneur, we provide solid waste management services including waste 
    collection & operation improvement, targeting low-value waste such as Used Beverage 
    Carton (UBC) - eg: Milk carton/juice packaging. We have started building our services 
    and products to help businesses and local authorities to 
    eliminate the impacts of low-value waste on the environment via the circular economy concept.`,
    img: image3
  },
  {
    label: "Create an ad",
    description: `Lagom Vietnam was established in early 2019 as an environmental 
    services entrepreneur, we provide solid waste management services including waste 
    collection & operation improvement, targeting low-value waste such as Used Beverage 
    Carton (UBC) - eg: Milk carton/juice packaging. We have started building our services 
    and products to help businesses and local authorities to 
    eliminate the impacts of low-value waste on the environment via the circular economy concept.`,
    img: image4
  },
];

export default function SwipeActivity() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px"
        }}
      >
        <Typography variant="h8">{steps[activeStep].label}</Typography>
      </Paper>

      <Box sx={{ height: "auto", width: "100%", background: "white", padding: "10px 10px", textTransform: "none" }}>
        <img style={{ height: "auto", width: "100%", background: "white" }} src={steps[activeStep].img} />
        <p style={{ fontWeight: "normal", fontSize: "0.9em" }}>
          {steps[activeStep].description}
        </p>

      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        sx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px"
        }}

        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </>
  );
}