const Content = ({ listContent }) => {
  return (
    <p>
      {listContent &&
          listContent.map((item, index) => (
            <>
              <div className="content-title" key={index}>
                <div className="image" key={index}>{item.image}</div>
                <div className="text" key={index}>{item.content}</div>
              </div>
            </>
          ))}
    </p>
  );
};
export default Content;