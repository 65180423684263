/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import SwipperItem from "./SwipperItem";

const items = [
  {
    link: "https://thanhnien.vn/sieu-mau-ho-duc-vinh-to-chuc-thanh-cong-giai-marathon-dream-cup-2023-185230731105311658.htm?fbclid=IwAR09P029KwSj6efDlzGeDF31riIYGqCWKO3HjQ_DbK5IrNcAeMxY91LSUcM_aem_AUZrAZ9hiP3xGyvwm6uBfcoB5YCLrMr5FWvnWqqbKH5i_66bCbAgT2ACt2e2vZYPBls",
    linkContent: "https://thanhnien.vn/sieu-mau-ho-duc-vinh-to-chuc-tha...2vZYPBls",
    title: "Siêu mẫu Hồ Đức Vĩnh tổ chức thành công giải Marathon Dream Cup 2023.",
    description: (
      <>
        <p>Marathon Dream Cup 2023 hướng đến thông điệp “Reach Your Dream - Chinh phục ước mơ”. Mỗi người sẽ có những ước...</p>
      </>),
    img: "https://images2.thanhnien.vn/528068263637045248/2023/7/31/hong-le-3-1690774962422323539714.jpg"
  },
  {
    link: "https://thanhnien.vn/ho-duc-vinh-quynh-thu-trao-qua-tet-cho-nguoi-khuyet-tat-1851542531.htm",
    linkContent: "https://thanhnien.vn/ho-duc-vinh-quynh-thu-trao...531.htm",
    title: "Hồ Đức Vĩnh, Quỳnh Thư trao quà tết cho người khuyết tật.",
    description: (
      <>
        <p>Hôm 15.1, Hồ Đức Vĩnh cùng nhiều người đẹp góp mặt trong chương trình Xuân yêu thương, nhằm hướng đến... </p>
      </>),
    img: "https://images2.thanhnien.vn/Uploaded/thanhlongn/2023_01_16/img-1124-9451.jpeg"
  },
  {
    link: "https://thanhnien.vn/sieu-mau-ho-duc-vinh-to-chuc-thanh-cong-su-kien-diamond-fashion-night-185231030152659188.htm?fbclid=IwAR1G72x9YQgOHLhFb7HS59yKRIH3cKKIYpleT1qlSzrDuMVQRARAAzYHBqY_aem_ATo41HXcpKMSIXTmUXtQr_dLH0pWH7fIV4TAYf_1IDUk6w3Hrnwl0XQNcBMnDPmNlF4",
    linkContent: "https://thanhnien.vn/sieu-mau-ho-duc-vinh-to-chuc-thanh-cong...cBMnDPmNlF4",
    title: "Siêu mẫu Hồ Đức Vĩnh tổ chức thành công sự kiện Diamond Fashion Night.",
    description: (
      <>
        <p>Diamond Fashion Night được tổ chức vào ngày 28/10/2023 tại New World Saigon Hotel là sự kiện thời trang do Diamond Entertainment phối...</p>
      </>),
    img: "https://images2.thanhnien.vn/528068263637045248/2023/10/30/g1-1698653994432636053530.jpeg"
  },
  {
    link: "https://www.saostar.vn/giai-tri/thi-sinh-face-viet-nam-tu-tin-catwalk-ben-canh-sieu-mau-dien-trai-ho-duc-vinh-1333219.html",
    linkContent: "https://www.saostar.vn/giai-tri/thi-sinh-face-...-trai-ho-duc-vinh-1333219.html",
    title: "Thí sinh The Face Việt Nam tự tin catwalk bên cạnh siêu mẫu điển trai Hồ Đức Vĩnh",
    description: (
      <>
        <p>Để chuẩn bị cho những thử thách cam go tại nhà chung, các cô gái của The Face Việt Nam đã có những...</p>

      </>
    ),
    img: "https://ss-images.saostar.vn/wwebp700/2017/06/08/1333219/catwalk-6.jpg"
  },
  {
    link: "https://ngoisao.vnexpress.net/ho-duc-vinh-luyen-catwalk-cho-thi-sinh-sieu-mau-viet-nam-3764652.html",
    linkContent: "https://ngoisao.vnexpress.net/ho-duc-vinh-luyen-catwalk-ch...52.html",
    title: "Hồ Đức Vĩnh luyện catwalk cho thí sinh Siêu mẫu Việt Nam.",
    description: (
      <>
        <p>Chàng người mẫu tận tình hướng dẫn cách sải bước và tạo dáng trên sàn diễn cho 'đàn em' trước khi bước vào vòng ghi hình.</p>
      </>),
    img: "https://i1-ngoisao.vnecdn.net/2018/06/16/duc-vinh-1-6030-1529156397.jpg?w=680&h=0&q=100&dpr=1&fit=crop&s=xKjVqhePZ7DofRvqhV5sgg"
  },

  {
    link: "https://www.kinhtenews.com/2023/04/diamond-entertainment-chinh-thuc-ra-mat-tai-vietnam.html?m=1",
    linkContent: "https://www.kinhtenews.com/2023/04/diamond-entert...nam.html?m=1",
    title: "Diamond Entertainment chính thức ra mắt tại Việt Nam.",
    description: (
      <>
        <p>KINHTENEWS - Ngày 23/4 vừa qua, lễ ra mắt công ty Diamond Entertainment đã được tổ chức thân mật với sự tham dự của 50 khách VIP,....</p>
      </>),
    img: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg01U65pi-UHLzo6Cw_YEAiCGR0Jr6HFZ1Uw5KGbxxeVLJa5bu15dgE5rcWxjskO472FkTaP4Ju7yH_uS54zGDUZ9Zn3NJXwPXuDmmsXoEi-KIS8E8cC9Gko0dWhAnKigmlcbb-SKRP_448blfSakRI2DOOI9LDG2AY3q2ljjkIgJxbi8NKIjcKfMJL/w640-h428/341901861_191955617022392_5940435143371914619_n.jpg"
  },

  {
    link: "https://doanhnhanngaynay.vn/2023/04/28/diamond-entertainment-quy-tu-dan-khach-moi-vip-va-showbiz-den-tham-du/",
    linkContent: "https://doanhnhanngaynay.vn/2023/04/28/diamond-entertain...tham-du/",
    title: "Diamond Entertainment quy tụ dàn khách mời VIP và showbiz đến tham dự.",
    description: (
      <>
        <p>(DNNN) – Người mẫu, diễn viên, NSX Trương Ngọc Ánh; Đạo diễn Hoàng Nhật Nam; Á hậu Thanh Hoài; MC Nguyệt Ánh; vợ chồng diễn viên Thanh Duy....</p>
      </>),
    img: "https://doanhnhanngaynay.vn/wp-content/uploads/2023/04/a3e4bc29b5016a5f3310-768x527.jpg"
  },
  {
    link: "https://vietnamnet.vn/sieu-mau-ho-duc-vinh-va-dan-sao-viet-to-chuc-giai-chay-tu-thien-2157686.html?fbclid=IwAR1yxgX6i5kncPq483uxH9CyEcL0ooHstQABNyd6Q8k95S695lgxI3HsQ0w_aem_AUZtwVIOfWKwRNy2JsNSO1DwuMxwlJ-krELV0TxTBYlG1GHxL7dnsjHaOfM1iljQRfM",
    linkContent: "https://vietnamnet.vn/sieu-mau-ho-duc-vinh-va-dan-sao-viet-to-chuc-giai...L7dnsjHaOfM1iljQRfM",
    title: "Siêu mẫu Hồ Đức Vĩnh và dàn sao Việt tổ chức giải chạy từ thiện.",
    description: (
      <>
        <p>Siêu mẫu Hồ Đức Vĩnh và loạt sao Việt cùng tham gia vào giải chạy từ thiện Marathon Dream Cup 2023 sắp diễn ra tại TPHCM.</p>
      </>),
    img: "https://static-images.vnncdn.net/files/publish/2023/6/23/ho-duc-vinh-555.jpeg"
  },
  {
    link: "https://vtv.vn/the-thao/cung-duong-dep-nhat-tp-ho-chi-minh-tro-thanh-duong-chay-cho-runners-cua-marathon-dream-cup-2023-20230623095257109.htm?fbclid=IwAR2YQg6_eMKlnkDM_dxJQ-ZqOcDWUR18Hrw2NmqcM6rCXvhyT6gTIHEmhdQ_aem_AUabd5HGLugNWwwfWrLAnPh224lVkNSaXLe5ryHA1reRQFiNmSqnGyGvAq8e2pBobMY",
    linkContent: "https://vtv.vn/the-thao/cung-duong-dep-nhat-tp-ho-chi-minh...GvAq8e2pBobMY",
    title: "Cung đường đẹp nhất TP Hồ Chí Minh trở thành đường chạy cho Runners của Marathon Dream Cup 2023.",
    description: (
      <>
        <p>VTV.vn - Marathon Dream Cup 2023 nhằm thúc đẩy tinh thần thể dục thể thao rèn luyện thể chất lẫn của cộng đồng với thông điệp "Reach Your Dream – Chinh phục ước mơ".</p>
      </>),
    img: "https://vtv1.mediacdn.vn/thumb_w/640/562122370168008704/2023/6/23/2-1-16874886910101129916756.jpeg"
  },

  {
    link: "https://thethaovanhoa.vn/vdv-pham-thi-hong-le-cung-dan-sao-viet-chay-marathon-gay-quy-thien-nguyen-gan-500-trieu-dong-2023073110464394.htm?fbclid=IwAR30qwhS_A1XpTVSePdckC9JcCn9wFw9s68IMJmhipwXNgKDltUJpFWUSEI_aem_AUYjTNnXpA0a5LYooEz9kkCNerbnRcmgflf0NuVU41BItkjTD5Ns1d3yqSjF9yed5I4",
    linkContent: "https://thethaovanhoa.vn/vdv-pham-thi-hong-le-cung-dan-sao-viet...3yqSjF9yed5I4",
    title: "VĐV Phạm Thị Hồng Lệ cùng dàn sao Việt chạy Marathon gây quỹ thiện nguyện gần 500 triệu đồng.",
    description: (
      <>
        <p>Giải Marathon Dream Cup 2023 vừa diễn ra tại Tp.HCM quy tụ hơn 2000 runner tham gia...</p>
      </>),
    img: "https://thethaovanhoa.mediacdn.vn/thumb_w/650/372676912336973824/2023/7/31/12-169077500706968017172.jpg"
  },
  {
    link: "https://dantri.com.vn/giai-tri/sieu-mau-ho-duc-vinh-song-ra-sao-sau-khi-rut-khoi-san-catwalk-20231002125609859.htm?fbclid=IwAR0-LbbOEqLxbjVUVp3o-kNXjJWpHJhGHcbIsYSH89F3NRwW0QKSXGSsZLo_aem_AUY3T-TNHV1KjrjHF0u7YHLr2n1ektuzm7dmjzeGVIBYOgU4JvkRUcKqjUpP0dWyK34",
    linkContent: "https://dantri.com.vn/giai-tri/sieu-mau-ho-duc-vinh-song-ra-sao-sau...pP0dWyK34",
    title: "Siêu mẫu Hồ Đức Vĩnh sống ra sao sau khi rút khỏi sàn catwalk?",
    description: (
      <>
        <p>(Dân trí) - Từng giành giải Vàng Siêu mẫu Việt Nam 2003 và là một trong những nam người mẫu "tung hoành" trên sàn...</p>
      </>),
    img: "https://cdnphoto.dantri.com.vn/WJ10oc9HWoCzMwop3TJnC1cvnGw=/thumb_w/1020/2023/10/02/anh-chup-man-hinh-2023-10-02-luc-124328-1696225503123.png"
  },
  {
    link: "https://ngoisao.vnexpress.net/le-giang-quynh-thu-du-su-kien-cua-ho-duc-vinh-4658911.html?fbclid=IwAR1eqdeUTOvvWR15GAgsM8XLypQDA5rlqwxwWOS3m9BNYSZV1aDgtJxP0oM_aem_AUbneZf1nuBD3RiT-VEb_WXgmK80m6yWlXqx6Qz6jHhEuYFZ2D_3EzFtPgHdWksFaMo",
    linkContent: "https://ngoisao.vnexpress.net/le-giang-quynh-thu-du-su-kien-cua-ho-duc-vi...sFaMo",
    title: "Lê Giang, Quỳnh Thư dự sự kiện của Hồ Đức Vĩnh.",
    description: (
      <>
        <p>Diễn viên Lê Giang, người mẫu Quỳnh Thư, hoa hậu Phan Thị Mơ cùng nhiều 'chân dài' có mặt trong tiệc của Hồ Đức Vĩnh, tối 28/9.</p>
      </>),
    img: "https://i1-ngoisao.vnecdn.net/2023/09/29/unnamed-4-1695970378.jpg?w=1200&h=0&q=100&dpr=1&fit=crop&s=PktqfmuXGx0Yspx-IeeIKA"
  }
];
export default function SwiperActivity() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem link={item.link} linkContent={item.linkContent} title={item.title} description={item.description} image={item.img} />

        </SwiperSlide>;
      })}
    </Swiper>
  );
}