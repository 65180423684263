import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js.js";
import ExpertiseBar from "./ExpertiseBar.js";

const Expertise = () => {
  return (
    <>
      <Box className="expertise">
        <NameContentHeader text="Chuyên môn" />

        <Box className="expertise-box">
          <Box className="expertise-content">
            <p>Bán Hàng</p>
            <p>Marketing</p>
            <p>Điều hành doanh nghiệp</p>
            <p>Truyền thông, báo chí</p>

          </Box>

          <Box className="expertise-bar">
            <ExpertiseBar value={90} />
            <ExpertiseBar value={70} />
            <ExpertiseBar value={86} />
            <ExpertiseBar value={95} />
          </Box>
        </Box>
      </Box >
    </>
  );
};
export default Expertise;