/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BASE64_PART, BASE64_PARTS_LENGTH, FIRST_VISIT_PAGE_POSITION, VCARD_FORMAT } from "./constants";
import Button from "components/Button";
import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { Buffer } from "buffer";
import useScrollListener from "./hooks/useScrollListener";
import { convertImageUrlToBase64 } from "utils/Image";
import Avatar from "assets/images/business_HaoHanh/Avatar.jpg";
const SaveContactButton = ({
  contact = {
    name: "",
    phone: "",
    email: "",
    url: "",
    image: ""
  }
}) => {
  const scroll = useScrollListener();
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    const isFirstRender = () => {
      return scroll.y === FIRST_VISIT_PAGE_POSITION && scroll.lastY === FIRST_VISIT_PAGE_POSITION;
    };

    const handleShowAndHideByScroll = () => {
      if (isFirstRender()) {
        setIsShow(true);
        return;
      }
      if (scroll.y < scroll.lastY) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    };

    handleShowAndHideByScroll();
  }, [scroll.x, scroll.y]);

  const formatVCardInfo = (key, value) => {
    if (key && value) {
      return `${key}${value}\n`;
    }
    return "";
  };

  const getVcardFormat = async () => {
    // const image = await convertImageUrlToBase64("https://lh3.googleusercontent.com/i7cTyGnCwLIJhT1t2YpLW-zHt8ZKalgQiqfrYnZQl975-ygD_0mOXaYZMzekfKW_ydHRutDbNzeqpWoLkFR4Yx2Z2bgNj2XskKJrfw8");
    return "BEGIN:VCARD" + "\n" +
      "VERSION:3.0" + "\n" +
      formatVCardInfo(VCARD_FORMAT.name, contact.name) +
      formatVCardInfo(VCARD_FORMAT.phone, contact.phone) +
      formatVCardInfo(VCARD_FORMAT.email, contact.email) +
      formatVCardInfo(VCARD_FORMAT.url, contact.url) +
      formatVCardInfo(VCARD_FORMAT.image, contact.image) +
      "END:VCARD";
  };

  const saveContact = async () => {
    const vcard = await getVcardFormat();
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const newLink = document.createElement("a");
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;

    newLink.click();
  };

  return (
    <Box sx={{
      position: "fixed",
      bottom: "1rem",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 99999,
      transition: "all 0.5s ease-in-out",
      opacity: isShow ? "1" : "0"
    }}>
      <Button variant="contained" onClick={saveContact} startIcon={<SaveIcon />}>Save Contact</Button>
    </Box>
  );
};

export default SaveContactButton;