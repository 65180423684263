import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import NameContentHeader from "../NameContentHeader.js";
import image1 from "assets/images/persional_ThongTrungLe/logon1.jpg";
import image2 from "assets/images/persional_ThongTrungLe/logon2.jpg";
import image3 from "assets/images/persional_ThongTrungLe/logon3.jpg";
import image4 from "assets/images/persional_ThongTrungLe/logon4.jpg";
import image5 from "assets/images/persional_ThongTrungLe/logon5.jpg";
import image6 from "assets/images/persional_ThongTrungLe/logon6.jpg";
import icon from "assets/images/icons8-image-50.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: image1,
  },
  {
    label: "Bird",
    imgPath: image2,
  },
  {
    label: "Bali, Indonesia",
    imgPath: image3,
  },
  {
    label: "Goč, Serbia",
    imgPath: image4,
  },
  {
    label: "Goč, Serbia",
    imgPath: image5,
  },
  {
    label: "Goč, Serbia",
    imgPath: image6,
  },
];

function SwipeableTextMobileStepper({ background }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (

    <Box className="work-experience" style={{ background: background }}>
      <NameContentHeader text="My images" icon={icon} />
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 20,
          pl: 2,
          bgcolor: "background.default",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px"
        }}
      >
        {/* <Typography>{images[activeStep].label}</Typography> */}
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: "auto",
                  display: "block",
                  // maxWidth: 800,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px"
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;