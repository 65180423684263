const Content = ({ listContent }) => {
  return (
    <p>
      {listContent &&
          listContent.map((item, index) => (
            <>
              <div key={index} className="content-item">
                <div className="circle"></div>
                <div className="bar"></div>
                <div key={index} className="year">{item.year}</div>
                {item.content.map((subItem, index) => (
                  <div className="text" key={index}>{subItem}</div>
                ))}
              </div>
            </>
          ))}
    </p>
  );
};
  
export default Content;