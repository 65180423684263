/* eslint-disable max-len */
import React from "react";
// import ContentText from "../../components/text/ContentText";
import Section from "../../components/Section";
import ContentText from "../../components/text/ContentText";
const WhyChooseUs = () => {
  return (
    <Section title={"WHY CHOOSE US"}>
      <ContentText>
        Our vision at Beetech is to become the premier destination for book enthusiasts worldwide. We aspire to create a space that is not only a place for book shopping but also a vibrant community where people connect, share opinions, and indulge in a shared passion for literature.
      </ContentText>
    </Section>
  );
};

export default WhyChooseUs;