/* eslint-disable max-len */
import React from "react";
// import ContentText from "../../components/text/ContentText";
import Section from "../../components/Section";
import { Box } from "@mui/material";
import CheckListItem from "../../components/CheckListItem";

const About = () => {
  return (
    <Section title={"ABOUT COMPANY"}>
      {/* <ContentText>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </ContentText> */}
      <Box marginTop={"1rem"}>
        <CheckListItem content={"Specializing in the production and processing of vegetables and fruits that meet standards for export to the EU, US, Japan, South Korea, and Australia."} />
        <CheckListItem content={" Our products are fully certified for food safety, including HACCP and FDA."} />
        <CheckListItem content={"We also produce and export dried fruits, processed fruits, and packaged fruits."} />
        <CheckListItem content={"We are trusted by our foreign partners."} />
      </Box>
    </Section>
  );
};

export default About;