/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
// import Content from "./Content.js";
import icon from "assets/images/icons8-highlight-50.png";

const HighLights = ({ background }) => {
  return (
    <>
      <Box className="work-experience" style={{ background: background }}>
        <NameContentHeader text="HighLights" icon={icon} />
        {/* <Content listContent={listContent} /> */}
        {/* <TimelineTree /> */}
        <div style={{ marginLeft: "10px", fontSize: "0.9em", fontWeight: "normal", textTransform: "none" }}>
          Recycle For Good
        </div>
      </Box >

    </>
  );
};
export default HighLights;