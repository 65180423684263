import { alpha } from "@mui/material";

const styleBtnContained = (color, theme) => {
  if (theme) {
    return {
      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].main,
      boxShadow: `0 8px 16px 0 ${alpha(theme.palette[color].main, 0.24)}`,
      "&:hover": {
        backgroundColor: theme.palette[color].dark,
      },
    };
  }
};
const Button = (theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          textTransform: "capitalize",
          borderRadius: "8px",
        },
        sizeLarge: {
          height: 48,
        },

        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          ...styleBtnContained("primary", theme),
        },
        containedSecondary: {
          ...styleBtnContained("secondary", theme),
        },
        outlinedPrimary: {
          color: "var(--text-color-button-outlined-base)",
          border: `1px solid ${theme.palette["primary"].darker}`,
          boxShadow: ` 0 -2em 1em -1em  ${alpha(theme.palette["primary"].main, 0.24)} inset`
        },
        containedError: {
          ...styleBtnContained("error", theme),
        },
        containedSuccess: {
          ...styleBtnContained("success", theme),
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]} `,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          color: theme.palette["primary"].darker,
        }
      },
    },
  };
};

export default Button;