import { Box, Typography } from "@mui/material";
import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import Avatar from "assets/images/business_HaoHanh/Avatar.jpg";

const contactStyle = {
  display: "block",
  textDecoration: "none",
  fontSize: "1em",
  color: "#fff",
  textAlign: "center",
};

const Cover = () => {
  return (
    <Box className="cover">
      <Box margin={"1rem"}>
        <img src="https://beetech.com.vn/assets/img/common/logo.png" alt="logo" style={{ width: "5em" }} />
      </Box>
      <Box className="quadrangle title">
        <Box className="quadrangle title title-border" />
      </Box>
      <Box className="company-name-container">
        <Typography component={"span"} style={{ color: "white" }} className="corporate">CORPORATE</Typography>
        <Typography component={"span"} style={{ color: "white" }} className="company-name">BEETECH</Typography>
      </Box>
      <Box className="quadrangle avatar"
        // eslint-disable-next-line quotes
        sx={{ backgroundImage: `url(https://beetech.com.vn/wp/wp-content/uploads/2021/01/rfid-service.jpg)`, backgroundPosition: "center" }}
      >
        <Box className="reverse">

        </Box>
      </Box>
      <Box className="quadrangle avatar avatar--after" />
      <Box className="contact-container">
        <Box className="quadrangle" />
        <Box className="contact">
          <Typography variant="h2" component={"div"} sx={{
            display: "flex", flexDirection: "column", justifyContent: "center",
          }}>
            <LocalPhoneOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff",
              marginBottom: "1rem"
            }} />
            <EmailOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff",
              marginBottom: "1rem"
            }} />
            <LanguageOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff"
            }} />
          </Typography>
          <Box marginLeft={"1rem"}>
            <Typography component={"a"} href="tel:0933204868"
              sx={contactStyle}
              mb={"1rem"}>
              +84 (028) 66529295</Typography>
            <Typography component={"a"} href="info@beetech.com.vn"
              sx={contactStyle}
              mb={"1rem"}>
              info@beetech.com.vn</Typography>
            <Typography
              sx={contactStyle}
              component={"a"}
              href="https://beetech.com.vn"
            >
              beetech.com.vn</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="quadrangle quadrangle--extra" />
    </Box>
  );
};

export default Cover;
