/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Box, Button, Typography } from "@mui/material";
// import video from "assets/video/A fight for education like you’ve never seen before _ UNICEF.mp4";
// import { autoPlay } from "react-swipeable-views-utils";
// import SwipeableViews from "react-swipeable-views";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ReactPlayer from "react-player";
import Section from "../../components/Section.js";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Dragon Fruit Wine",
    imgPath:
      "https://www.youtube.com/watch?v=Zx_DsBckP8c",
  },
  {
    label: "Plants grown with imported organic and biological fertilizers from Canada produce sweet and juicy fruits that are guaranteed to be nutritionally complete",
    imgPath:
      "https://www.youtube.com/watch?v=tN2v-8vb3fME",
  },

  {
    label: "Fruit processing and packaging facility for export",
    imgPath:
      "https://www.youtube.com/watch?v=o7KC4dVMV8E",
  },
  {
    label: "Baby cucumber",
    imgPath:
      "https://www.youtube.com/watch?v=LLPGA9UA3fg",
  },
];

const Video = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };
  return (
    <Section title={"MY VIDEO"}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 2,
          bgcolor: "background.default",
          mb: "1rem"
        }}
      >
        <Typography fontWeight={"bold"}>{images[activeStep].label}</Typography>
      </Paper>
      <Box sx={{ background: "white" }} className="player-wrapper">
        <ReactPlayer url={images[activeStep].imgPath} className="react-player" width={"100%"} height={"100%"} />
      </Box>

      < MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          < Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )
            }
          </Button >
        }
        backButton={
          < Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )
            }
            Back
          </Button >
        }
      />
    </Section>
  );
};
export default Video;