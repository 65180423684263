import { Avatar, Box, Typography } from "@mui/material";
import avatar from "assets/images/persional_CaoQuangTien/BuiThiThaiAnh.jpg";

export const ContentHeader = () => {
  const marginTopValue = `-${0.6 * 150}px`;
  return (
    <>
      <Box
        className="templete2">
        <Avatar
          alt="Remy Sharp"
          src={avatar}
          sx={{ width: 150, height: 150, marginInline: "auto", marginTop: marginTopValue }}
        />
        <Typography className="content-header-name">Bùi Thị Thái Anh</Typography>

        <Box className="box-content-header-job">
          <Typography className="content-header-job">Giám đốc điều hành Mytech Việt Nam
          </Typography>
        </Box>

      </Box>
    </>
  );
};