import React from "react";
import LogoBeetechText from "assets/images/LogoBeetechText.png";
import { Box } from "@mui/system";
const Logo = () => {
  return (
    <Box margin={"1rem"}>
      <img src={LogoBeetechText} />
    </Box>
  );
};

export default Logo;