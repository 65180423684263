import * as React from "react";
import Box from "@mui/material/Box";
import NameContentHeader from "../NameContentHeader.js.js";
import icon from "assets/images/icons8-image-50.png";
import SwiperImages from "./SwiperImages.js/index.js";

function MyImage({ background }) {

  return (

    <Box className="work-experience" style={{ background: background }}>
      <NameContentHeader text="My images" icon={icon} />
      <SwiperImages />
    </Box>
  );
}

export default MyImage;