import React from "react";
import Section from "../../components/Section";
import Skill from "./components/Skill";

const Expertise = () => {
  return (
    <Section title={"KỸ NĂNG"}>
      <Skill skill={"Chuyên tư vấn hướng dẫn kỹ năng Catwalk."} percent={96} />
      <Skill skill={"Kỹ năng tổ chức sự kiện VIP"} percent={85} />
      <Skill skill={"Stylist: hướng dẫn tạo dáng cho người mẫu trước ống kính."} percent={88} />
    </Section>
  );
};

export default Expertise;