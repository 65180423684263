import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const Time = ({ time }) => {
  const theme = useTheme();
  return (
    <Box sx={{
      background: theme.palette.primary.main,
      padding: "0 0.2rem",
      width: "fit-content",
      height: "fit-content",
      color: theme.palette.primary.contrastText,
      borderRadius: "4px",
      textWrap: "nowrap",
      marginLeft: "0.4rem"
    }}>{time}</Box>
  );
};

export default Time;