import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Cover from "./section/cover";
import About from "./section/about";
import WhyChooseUs from "./section/whyChooseUs";
import Mission from "./section/mission";
import Vision from "./section/vision";
import Team from "./section/team";
import Service from "./section/services";
import ContactUs from "./section/contactUs";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInfo } from "lib/redux/feature/business-profile";
import dummyData from "assets/dummy-data";

const Template1 = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const businessInfo = useMemo(() => {
    if (params?.id) {
      const businessData = dummyData[params.id];
      return businessData;
    }
  }, [params]);
  useEffect(() => {
    if (businessInfo) {
      dispatch(setInfo(businessInfo));
    }
  }, [businessInfo]);
  return (
    <Box sx={{ background: "#4b4a4e" }}>
      <Cover />
      <Box sx={{ width: "100vw", minHeight: "100vh", padding: "24px", marginTop: "-1px" }}>
        <About />
        <WhyChooseUs />
        <Mission />
        <Vision />
        <Team />
        <Service />
        <ContactUs />
      </Box>
    </Box>
  );
};

export default Template1;