import React from "react";
import ListItemWithIcon from "../../components/ListItemWithIcon";
import Section from "../../components/Section";
import Phone from "assets/images/Phone.png";
import Email from "assets/images/Email.png";
import Location from "assets/images/Location.png";
const ContactUs = () => {
  return (
    <Section title={"Contact us"}>
      <ListItemWithIcon
        marginBottom="0.6rem"
        icon={<img src={Location} width={"30"} height={"30"} />}
        content={"273 Đ. An Đường Vương, Phường 3, Quận 5, Thành phố Hồ Chí Minh"}
      />
      <ListItemWithIcon
        marginBottom="0.6rem"
        icon={<img src={Phone} width={"30"} height={"30"} />}
        content={"+84 349 822 717"}
      />
      <ListItemWithIcon
        icon={<img src={Email} width={"30"} height={"30"} />}
        content={"a.nguyen@lorem.com.vn"}
      />
    </Section>
  );
};

export default ContactUs;