import SwiperArticle from "./SwiperArticle.js/index.js";
import Section from "../../components/Section.js";

const Article = () => {
  return (
    <Section title={"TRUYỀN THÔNG ĐƯA TIN"}>
      <SwiperArticle />
    </Section>
  );
};
export default Article;