/* eslint-disable max-len */
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import TitleText from "./text/TitleText";

const Section = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box className="section">
      <TitleText>{title}</TitleText>
      <Box className="divider" sx={{ backgroundColor: theme.palette.primary.main }} />
      {children}
    </Box>
  );
};

export default Section;