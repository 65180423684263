import NameContentHeader from "../NameContentHeader";
import Data from "./Content";
import { Box } from "@mui/system";

const Contents = [
  {
    title: "FRONTEND",
    content: ["ReactJs", "VueJs", "EJS", "JS"]
  },
  {
    title: "BACKEND",
    content: ["NodeJs", "Express", "Go"]
  },
  {
    title: "DATABASE",
    content: ["MongoDB", "SQL", "Fisebase"]
  }
];

const Skills = () => {
  return (
    <>
      <Box className ="skills">
        <NameContentHeader text="Skills" />
        <Data listContent={Contents} />
      </Box >
    </>
  );
};
export default Skills;
