/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import Section from "../../components/Section";
import HistoryTime from "./components/HistoryTime.js";
import { HistoryTime_DATA } from "./components/data.js";
import ContentText from "../../components/text/ContentText";

const listHistory = Array.from(Array(3)).map((el, index) => (
  <HistoryTime {...HistoryTime_DATA[index]} />
));
const CompanyHistory = () => {
  return (
    <Section title={"COMPANY HISTORY"}>
      {/* <Stack>{listHistory.map((el) => el)}</Stack> */}
      <ContentText>
        Has invested 6 years in the field of processing and providing safe, organic agricultural products for export to the EU, US, and Australia.
      </ContentText>
    </Section>
  );
};

export default CompanyHistory;
