import { Box, Typography } from "@mui/material";

export const ContentHeader = () => {
  return (
    <>
      <Box
        className="templete2">
        <Typography className="content-header-name">Nguyen Ngoc Anh</Typography>

        <Box className="box-content-header-job">
          <Typography className="content-header-job">UI/UX Designer</Typography>
        </Box>

      </Box>
    </>
  );
};