import React, { useEffect, useState } from "react";
import HeaderAvartar from "./components/HeaderAvartar";
import { ContentHeader } from "./components/ContentHeader";
import { Box, ThemeProvider } from "@mui/material";
import Contact from "./components/Contact";
import Introduce from "./components/Introduce";
import "assets/scss/persional-template1.scss";
import Expertise from "./components/Expertise";
import WorkExperience from "./components/WorkExperience";
import Interests from "./components/Interests";
import HighLight from "./components/HighLight";
import Video from "./components/Video";
import MyImage from "./components/MyImage";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import getTheme from "./mui-theme";
import avatar from "assets/images/persional_CaoQuangTien/BuiThiThaiAnh.jpg";
import { getImageToSaveContact } from "utils/convertImageToBase64";

const palette = {
  primary: {
    main: "#000",
    contrastText: "#fff"
  }
};

const Template1_BuiThiThaiAnh = () => {
  const [imageToSaveContact, setImageToSaveContact] = useState("");

  useEffect(() => {
    document.title = "Bui Thi Thai Anh";
    getImageToSaveContact(avatar, setImageToSaveContact);
  }, []);

  return (
    <ThemeProvider theme={getTheme({ palette })}>
      <Box className="persional-template1" >
        <Box style={{
          height: "100vh", margin: 0,
          padding: 0
        }}>
          < HeaderAvartar />
          <ContentHeader />
          <Contact />
        </Box>

        <Introduce />
        <HighLight />
        <Expertise />
        <Video />
        <MyImage />
        <WorkExperience />
        <Interests />
        <SaveContactButton contact={{
          name: "Bùi Thị Thái Anh",
          email: "thaianhtbkt@gmail.com",
          phone: "0978929259",
          image: imageToSaveContact
        }} />
      </Box >
    </ThemeProvider>
  );
};

export default Template1_BuiThiThaiAnh;