import { Box } from "@mui/system";
import ProfilePIC from "assets/images/PROFIL PIC.png";
import BoxImageCenterHeader from "./BoxImageCenterHeader";
import ContentHeader from "./ContentHeader";

const Header = () => {
  return (
    <>
      <Box className="header-template2-personal">
        <Box className="todo">
          <img className="circle" src={ProfilePIC} alt="Image header" />
          <BoxImageCenterHeader />
        </Box>
        <ContentHeader />
      </Box>

    </>
  );
};
export default Header;