/* eslint-disable max-len */
const Introduce = () => {
  return (
    <>
      <p className="introduce">Tôi có kinh nghiệm tư vấn giải pháp quản lý sản xuất sử dụng công nghệ RFID và AI.
        Với sự tập trung cao độ vào các giải pháp và công nghệ, tôi sẽ mang đến kiến
        thức chuyên môn chuyên nghiệp và các phương pháp giải quyết vấn đề hiệu quả
        tới các nhà máy, công ty. Mục tiêu của tôi là tối ưu hóa thời gian, nhân lực, chi phí trong vận hành phần mềm sản xuất.</p>
    </>
  );
};
export default Introduce;