/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "assets/css/global.css";

import routes from "lib/routes";
import ThemeProvider from "lib/mui-theme";

import Components from "pages/components";
import Profile from "pages/profile";
import Template1Bussiness from "pages/profile/templates/bussiness/template1";
import Template2Bussiness from "pages/profile/templates/bussiness/template2";
import Template2BusinessHaoHanh from "pages/profile/templates/bussiness/template2_HaoHanh";
import Template1Personal from "pages/profile/templates/personal/template1";
import Template2Personal from "pages/profile/templates/personal/template2";
import Template3Personal from "pages/profile/templates/personal/template3";
import Template4Personal from "pages/profile/templates/personal/template4";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import Template3Personal_ThongTrungLe from "pages/profile/templates/personal/template3_ThongTrungLe";
import Template2Beetech from "pages/profile/templates/bussiness/template2_Beetech";
import Template1_CaoQuangTien from "pages/profile/templates/personal/template1-CaoQuangTien";
import Template3_HoDucVinh from "pages/profile/templates/personal/template3-HoDucvinh";
import Template1_BuiThiThaiAnh from "pages/profile/templates/personal/template1-BuiThiThaiAnh";

const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Routes>
        <Route path={routes.components} element={<Components />} />
        <Route path={"/"} element={<Profile />} />
        <Route path={"/bussiness/template1/:id"} element={<Template1Bussiness />} />
        <Route path={"/bussiness/template2"} element={<Template2Bussiness />} />
        <Route path={"/business/haohanh"} element={<Template2BusinessHaoHanh />} />
        <Route path={"/business/beetech"} element={<Template2Beetech />} />

        <Route path={"/personal/template1"} element={<Template1Personal />} />
        <Route path={"/personal/template2"} element={<Template2Personal />} />

        <Route path={"/personal/ThongTrungLe/*"} element={<Template3Personal_ThongTrungLe />} />

        <Route path={"/personal/template1/CaoQuangTien"} element={<Template1_CaoQuangTien />} />
        <Route path={"/personal/template1/BuiThiThaiAnh"} element={<Template1_BuiThiThaiAnh />} />

        <Route path={"/personal/template3"} element={<Template3Personal />} />
        <Route path={"/personal/template3/HoDucVinh"} element={<Template3_HoDucVinh />} />

        <Route path={"/personal/template4"} element={<Template4Personal />} />
      </Routes>
      {/* <SaveContactButton /> */}
    </ThemeProvider>
  );
};

export default App;