import { Box } from "@mui/system";

const NameContentHeader = ({ text, icon }) => {

  return (
    <>
      <Box classname="name-content-header" style={{ marginTop: "5px" }}>
        <img src={icon} width={18} height={18} style={{ marginRight: "5px" }} alt="Custom Icon" /> {text}
        <div className="bar-under-name"></div>
      </Box>
    </>
  );
};

export default NameContentHeader;