/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import SwipeActivity from "./SwipeActiviry.js";
import icon from "assets/images/icons8-bullet-list-50.png";

const Activity = ({ background }) => {
  return (
    <>
      <Box className="expertise" style={{ background: background }}>
        <NameContentHeader text="Activity" icon={icon} />
        <SwipeActivity />
      </Box >

    </>
  );
};
export default Activity;