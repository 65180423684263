import { Box } from "@mui/system";
import React from "react";
import { coverStyles } from "assets/js/personal-template3";
import { useTheme } from "@mui/material/styles";
const ShapeBackground = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={coverStyles({ palette: theme.palette }).shapeBackground.rectangle}></Box>
    </Box>
  );
};

export default ShapeBackground; 