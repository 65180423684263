/* eslint-disable no-unused-vars */
import React from "react";
import CompanyHistoryIcon from "assets/images/company_history_icon.png";
import { Box, Stack, Typography } from "@mui/material";

const HistoryTime = ({ year, description }) => {
  return (
    <Stack className="timeline">
      <div class="container">
        <div class="quadrangle"></div>
        <div class="time">2022</div>
        <div class="underline"></div>
      </div>
      <Typography margin={1}>{description}</Typography>
    </Stack>
  );
};

export default HistoryTime;
