import React from "react";
import PropTypes from "prop-types";
// material
import { CssBaseline } from "@mui/material";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
//
import palette from "assets/js/palette";
import typography from "./typography";
import shadows, { customShadows } from "./shadows";
import componentsOverrides from "./override";

// ----------------------------------------------------------------------
ThemeProvider.propTypes = {
  children: PropTypes.node,
};
const themeOptions = {
  palette,
  typography,
  shadows,
  customShadows,
  shape: { borderRadius: 6 },
};
export const theme = createTheme(themeOptions);
theme.components = componentsOverrides(themeOptions);

export default function ThemeProvider({ children }) {
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}