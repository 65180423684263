/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from"../NameContentHeader";
import Data from "./Content";
import Climbing from "assets/images/climbing.png";
import Writing from "assets/images/writing.png";
import Reading from "assets/images/stack-of-books.png";
import Laptop from "assets/images/laptop.png";
import Hand from "assets/images/point.png";

const Contents = [
  {
    image:  
    <>
      <img src={Climbing} alt="avatar"/>
    </>,
    content: "Climbing"
  },
  {
    image:  
    <>
      <img src={Writing} alt="avatar"/>
    </>,
    content: "Writing Articles"
  },
  {
    image:  
    <>
      <img src={Reading} alt="avatar"/>
    </>,
    content: "Reading"
  },
  {
    image:  
    <>
      <img src={Laptop} alt="avatar"/>
    </>,
    content: 
    "Open Source Contributor A Blockchain, a CMS, a VanillaJS web app  “#3 Product of the day” on ProductHunt"
  },
  {
    image:  
    <>
      <img src={Hand} alt="avatar"/>
    </>,
    content: 
    "Want to discover them? Go to my website"
  }
];

const Hobby = ()=>{
  return (
    <>  
      <Box className="hobbies">
        <NameContentHeader text="Hobby" />
        <Data listContent={Contents} />
      </Box>
    </>
  );
};

export default Hobby;