/* eslint-disable max-len */
import React from "react";
import ListItemWithIcon from "../../components/ListItemWithIcon";
import Section from "../../components/Section";
import IconWithHexagonBackground from "../../components/IconWithHexagonBackground";
import Speaker from "assets/images/Speaker.svg";
const Service = () => {
  return (
    <Section title={"Our Services"}>
      <ListItemWithIcon
        marginBottomBase
        icon={<IconWithHexagonBackground color={"var(--primary-color)"} icon={<img src={Speaker} width={"20"} height={"20"} />} />}
        title={"Creative Marketing"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
      />
      <ListItemWithIcon
        marginBottomBase
        icon={<IconWithHexagonBackground color={"var(--primary-color)"} icon={<img src={Speaker} width={"20"} height={"20"} />} />}
        title={"Creative Technology"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
      />
      <ListItemWithIcon
        icon={<IconWithHexagonBackground color={"var(--primary-color)"} icon={<img src={Speaker} width={"20"} height={"20"} />} />}
        title={"Creative Branding"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
      />
    </Section>
  );
};

export default Service;