import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Language = ({ language, description }) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography variant="subtitle1">{language}</Typography>
      <Typography variant="content" margin={"0 0.4rem"}>{" - "}</Typography>
      <Typography variant="content">{description}</Typography>
    </Box>
  );
};

export default Language;