
const Content = ({ listContent }) => {
  return (
    <p>
      {listContent &&
        listContent.map((item, index) => (
          <>
            <div key={index} className="content-title">{item.title}</div>
            {
              item.content.map((subItem, index) => (
                <div key={index} className="content-text">{subItem}</div>
              ))}
          </>
        ))}
    </p>
  );
};
export default Content;