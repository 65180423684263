import { getGapBetweenCurvedBackgroundMaxWidthAndScreenWidth } from "utils/personal-template4/ShapeBackground";

export const coverStyles = () => {
  return {
    container: {
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff"
    },
    infoWithShapeBackgroundContainer: {
      position: "relative"
    },
    shapeBackground: {
      position: "absolute",
      zIndex: 3,
      top: `calc(24vh - ${getGapBetweenCurvedBackgroundMaxWidthAndScreenWidth()}px) `,
      "@media (min-width: 300px) and (max-width: 399px) and (max-height: 799px)": {
        top: "14vh",
      },

    },
    mainInfoContainer: {
      position: "absolute",
      width: "100vw",
      top: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 3
    },
    avatarCointainer: {
      borderRadius: "100%",
      width: "60vw",
      height: "60vw",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    avatar: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundImage: "url(https://www.nazafu.com/images/thumbs/2018/03/ao-vest-xam-chuot-1103-61-slide-products-5abdf6fc5001c.jpg)",
      height: "50vh",
      zIndex: 1
    },
    contactContainer: {
      width: "100vw",
      flex: 1,
      // marginTop: "16vh",
      zIndex: 4
    }
  };
};