import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";

const HighLight = () => {
  return (
    <>
      <Box className="highlight">
        <NameContentHeader text="Điểm nổi bật" />
        <p className="content-item">Thành công của bạn là thành công của tôi!
        </p>
      </Box>

    </>
  );
};
export default HighLight;