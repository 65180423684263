/* eslint-disable max-len */
import { Box } from "@mui/material";
import React from "react";
import TitleText from "./text/TitleText";

const Section = ({ title, children }) => {
  const titleParts = typeof title === "string" && title.split(" "); // Tách title thành mảng các phần

  const getNormalTitle = () => {
    try {
      return titleParts[0];
    } catch (e) {
      return "";
    }
  };

  const getHiglightTitle = () => {
    titleParts.shift();
    try {
      return titleParts.join(" ");
    } catch (e) {
      return "";
    }
  };

  return (
    <Box sx={{ padding: "1rem 0" }} className="section">
      <TitleText>{getNormalTitle()}{" "}
        <span className="hightlight-title"
        >{getHiglightTitle()}
        </span></TitleText> { }
      <Box
        sx={{
          height: "2px",
          background: "var(--bussiness-template-2-primary-color)",
          width: "1rem",
          margin: "0.8rem 0",
        }}
      />
      {children}
    </Box>
  );
};

export default Section;
