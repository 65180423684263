/* eslint-disable max-len */
import React from "react";
import Section from "../../components/Section";
import { Box } from "@mui/material";
import Content from "../../components/Quote";

const Mission = () => {
  return (
    <Box>
      <Section title={"MISSION"}>
        <Content content={"Exporting Vietnamese agricultural products to large, safe, and stable markets helps agricultural producers to maintain their output and compete with other countries."} />
      </Section>
    </Box>
  );
};

export default Mission;