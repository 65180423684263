import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import ItemInterest from "./ItemInterest.js";
import Badminton from "assets/images/Badminton.png";
import Traveler from "assets/images/Traveler.png";
import Music from "assets/images/Music.png";

const Interests = () => {
  return (
    <>
      <Box className="interests">
        <NameContentHeader text="Interests" />
        {/* <Content title={"Marketing"} listContent={listContent} /> */}
        <Box className="interest-content">
          <ItemInterest
            icon={<img src={Badminton} alt="Description of the image" />}
            contentIcon={"Badminton"} />
          <ItemInterest
            icon={<img src={Traveler} alt="Description of the image" />}
            contentIcon={"Traveler"} />
          <ItemInterest
            icon={<img src={Music} alt="Description of the image" />}
            contentIcon={"Music"} />
        </Box>
      </Box >

    </>
  );
};
export default Interests;