/* eslint-disable max-len */
const Introduce = () => {
  return (
    <>
      <p className="introduce">I have experience in advising production management solutions using RFID and AI technology.
        With a high focus on solutions and technology, I will bring professional expertise and effective problem-solving approaches
        to factories and companies. My goal is to optimize time, manpower, and costs in the operation of production software.</p>
    </>
  );
};
export default Introduce;