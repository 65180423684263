/* eslint-disable max-len */
const dummyData = {
  1234: {},
  1235: {},
  1236: {
    id: "1236",
    name: "Beetech",
    logo: "https://beetech.com.vn/assets/img/common/logo.png",
    avatar: "https://izisolution.vn/upload/2021/he-thong-bi-la-gi3.jpg",
    aboutCompay: "We are committed to providing our customers with smart solutions, helping to more optimize in management - increasing profitability as well as a competitive advantage, business opportunities, and accompanying our customers - as well as local or intensive partners in each market.",
    whyChooseUs: {
      content: "",
      subContent: [
        "With a team of experienced staff, experts who used to work in famous corporations. We are confident in providing customers with quality services.",
        "We are constantly researching, developing, and advancing technology. Create a breakthrough to provide customers with the best service options.",
        "Our development guidelines are to always put trust on top, commitment, and take responsibility for all products and services provided by us.",
        "We will professional advice and design solutions to suit your model, budget, and requirements. To help our customers save costs, optimize, and transparency in the management"
      ],
    },
    mission: [
      "Develop general software solutions to help businesses have an intelligent and efficient operating system.",
      "Constantly researching and improving to bring to customers and partners quality products and services.",
      "Building a working environment to help develop maximum capacity, knowledge, and working efficiency of staff.",
      "Accompanying and co-operating to bring more opportunities and benefits to business partners."
    ],
    vision: [
      "Become a recognized, respected, and trusted global leader in the IT field",
      "Leading distributor of solutions and technologies (AutoID; IoT; Al and LM) in Southeast Asia",
      "Cooperate with research and development of enterprise solutions that meet require of markets in Southeast Asia with global technology companies.",
      "Develop an ecosystem of products and services to provide opportunities business and accompany with local or specialized partners in each brand."
    ],
    companyHistory: [
      {
        time: "2008",
        title: "THE FIRST RFID PROJECT IN VIETNAM",
        description: "The name \"RFID deployment and development in Vietnam\" was conceived and approved by the Department of Science and Technology of Ho Chi Minh City and especially supported by the People's Committee of Ho Chi Minh City. At that time, Beetech, formerly Splendid, was the unit that received the trust of the Department of Science and Technology."
      },
      {
        time: "2009",
        title: "CONCLUSION AND PRIVILEGES",
        description: "Officially signed a cooperation contract with the Department of Science and Technology of Ho Chi Minh City as well as the People's Committee of Ho Chi Minh City."
      },
      {
        time: "2010",
        title: "FIRST CONTRACT",
        description: "Contracted \"Design and test production of remote identification chip by radio wave (RFID)\" according to the order of the Department of Science and Technology of Ho Chi Minh City."
      },
      {
        time: "2011",
        title: "REGISTER COPYRIGHT",
        description: "Registered and granted copyright certificates by the Copyright Office for 11 solutions (warehouse management, property management, in /out management, school management, ...)"
      },
      {
        time: "2012-2016",
        title: "FIRST PRODUCTS",
        description: "After 3 years of research, testing, and development and completion. Huy Hoang (Splendid) officially processed according to the orders of enterprises in Vietnam such as Ben Thanh Caravan A Tobacco Factory under Saigon Industrial Corporation, Vietnam Logoplaste Company (Portugal ), Alsok Vietnam, Q-Mobile, ... Taking part in some projects is a scientific research topic under the Department of Science and Technology of the city. Splendid pauses receiving business projects to focus on general and specialized Government projects such as Electronic Passport (E-passport), non-stop fee collection, ..."
      },
      {
        time: "2017",
        title: "BACKGROUND",
        description: "Bee Tech Co., Ltd. was established - Holding the responsibility of supporting corporate customers and developing solutions for the business. Inheriting Splendid process, key members, reconnecting customers, and old partners."
      },
      {
        time: "2017-2020",
        title: "INHERITING AND THRIVE",
        description: "Inheriting and thrive, affirming an important position for business partners, recognized by the market as the leading reliable solution provider (especially asserting the role pioneering role in RFID solutions in Vietnam). Beetech is the choice of many corporations and major companies in the world such as Decathlon, Nike (Moc Bai -Takwang), Naxis, Koamax, Bel, Hyosung, Leading Star, ..."
      },
      {
        time: "2017-2020",
        title: "EXPANSION ",
        description: "With a setup office in Hanoi. The aim to better support businesses in the North."
      }
    ],
    team: [
      {
        avatar: "https://beetech.com.vn/wp/wp-content/uploads/2021/03/Mr-Tran.jpg",
        name: "CU TRAN NGUYEN",
        jobTitle: "CEO",
        description: "Has 10+ years experience in RFID industry, especially successfully deployed RFID solutions for Non-stop automatic toll station projects, Factory production management, Warehouse Management, Security management...",
      },
      {
        avatar: "https://beetech.com.vn/wp/wp-content/uploads/2021/03/Haihb.jpg",
        name: "HAI HA",
        jobTitle: "SALES DIRECTOR",
        description: "He has 8 years experience in software development with 1 year worked at NEC Soft (japan), 10 years in system integration for Japanese enterprise segments and 2+ years in RFID industry.",
      },
      {
        avatar: "https://beetech.com.vn/wp/wp-content/uploads/2021/02/minh-dang.jpg",
        name: "QUANG MINH DANG",
        jobTitle: "Regional Sales managers",
        description: "He has more than 6 years experience in building up sales and marketing strategy, company process management, especially deep understanding the market in North Vietnam",
      }

    ],
    service: [
      {
        title: "RFID",
        description: "Industry 4.0 presents a unique challenge of efficiently transforming traditional manufacturing to smart and autonomous systems. Integrating manufacturing systems, materials, machinery, operators, products, and consumers, improve interconnectivity and traceability across the entire product life cycle in order to ensure the horizontal and vertical integration of networked Smart Manufacturing (SM) systems. RFID which is a key technology for Industry 4.0",
        icon: ""
      },
      {
        title: "BARCODE",
        description: "For more than four decades, barcodes have helped companies, large and small, more efficiently and accurately identify, track, and inventory a variety of products, assets, supplies, and more. The invention of the bar code made things easier and more convenient in warehouse management, retail, hospital, manufacturing, logistics,...",
        icon: ""
      },
      {
        title: "IOT & AI",
        description: "The manufacturing industry never gets outdated because of its high demand. As globalization sets in, the need for mass production increased. This eventually led to improvements in the science sector. The industry is known to update itself with the new technologies available. Artificial Intelligence and IoT are two innovative inclusions that the manufacturing industry is focusing on.",
        icon: ""
      },
      {
        title: "Website, Software Development",
        description: "We are a leading software development company that offers top-rated Software Development Services due to our vast experience, team of skilled professionals, key business insights, and dedicated working process.",
        icon: ""
      },
      {
        title: "Integrated Solutions",
        description: "Kết nối tất cả dữ liệu, con người và quy trình của bạn với nhau để tạo thành một giải pháp được kết nối hiện đã trở thành phương pháp hay nhất được chấp nhận ở bất kỳ công ty nào.",
        icon: ""
      },
    ],
    contactUs: {
      address: "30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc City, HCMC Vietnam",
      phone: "093.808.9669",
      email: "info@beetech.com.vn",
      website: "https://beetech.com.vn/ "
    }
  }
};
export default dummyData;