import React from "react";
import { ReactComponent as CheckMarkIcon } from "assets/images/Checkmark.svg";
import { Box } from "@mui/material";
const CheckMark = ({ width = "40px", height = "40px", color = "var(--primary-color)" }) => {
  return (
    <Box width={width} height={height}>
      <CheckMarkIcon fill={color} width={width} height={height} />
    </Box>
  );
};

export default CheckMark;