import { Box, Typography } from "@mui/material";
import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ICON_SIZE } from "./constants";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as FacebookIcon } from "assets/images/Facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/images/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/Linkedin.svg";
import { ReactComponent as TikTokIcon } from "assets/images/Tiktok.svg";
const Contact = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100vw",
        flex: 1,
        marginTop: "16vh"
      }}
    >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h2" component={"div"} sx={{
          display: "flex", flexDirection: "column", justifyContent: "center",
        }}>
          <LocalPhoneOutlinedIcon color="primary" sx={{ width: "1em", height: "1em", fontSize: "1em" }} />
          <EmailOutlinedIcon color="primary" sx={{ width: "1em", height: "1em", fontSize: "1em" }} />
          <LanguageOutlinedIcon color="primary" sx={{ width: "1em", height: "1em", fontSize: "1em" }} />
        </Typography>
        <Box marginLeft={"1rem"}>
          <Typography variant="h2" color={theme.palette.primary.main} textAlign={"center"}>
            +84 349 822 717 </Typography>
          <Typography variant="h2" color={theme.palette.primary.main} textAlign={"center"}>
            info@beetech.com.vn</Typography>
          <Typography variant="h2" color={theme.palette.primary.main} textAlign={"center"}>
            www.beetech.com.vn </Typography>
        </Box>
      </Box>
      <Typography variant="h2"
        color={theme.palette.primary.main}
        textAlign={"center"}
        margin={"1rem"}
      >
        30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc City, HCMC Vietnam
      </Typography>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} margin={"1rem"}>
        <Box marginRight={"1rem"}>
          <FacebookIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
        </Box>
        <Box marginRight={"1rem"}>
          <InstagramIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
        </Box>
        <Box marginRight={"1rem"}>
          <LinkedinIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
        </Box>
        <Box marginRight={"1rem"}>
          <TikTokIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
        </Box>
      </Box>
      <Typography variant="h2"
        color={theme.palette.primary.main}
        textAlign={"center"}
        margin={"1rem"}
        fontWeight={"bold"}
      >
        @TEOBEETECH
      </Typography>
    </Box>
  );
};

export default Contact;