import { Box } from "@mui/system";
import NameContentHeader from"../NameContentHeader";
import Data from "./Content";

const Contents = [
  {
    title: "FRENCH",
    content: "Mother Tongue"
  },
  {
    title: "ENGLISH",
    content: "Advanced level"
  }
];

const Language = ()=>{
  return (
    <>  
      <Box className="languages">
        <NameContentHeader text="Languages" />
        <Data listContent={Contents} />
      </Box>
    </>
  );
};

export default Language;