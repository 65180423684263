/* eslint-disable max-len */
import React from "react";
import { Box, Typography } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
const Content = ({ content }) => {
  const imageStyle = {
    marginTop: "20px",
    width: "20%",
    height: "auto",
    color: "var(--bussiness-template-2-primary-simple-color)",

  };
  return (
    <Box sx={{
      alignItems: "center",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    }}>
      <FormatQuoteIcon sx={imageStyle} />
      <Box
        sx={{
          margin: "0.8rem 0",
          padding: "0 1rem"
        }}>
        <Typography color={"black"} sx={{ fontSize: "1.3rem", fontStyle: "italic", fontWeight: "550", textAlign: "center" }}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
};
export default Content;