import { Box } from "@mui/system";
import NameContentHeader from "./NameContentHeader.js";

const Education = () => {
  return (
    <>
      <Box className="edutation">
        <NameContentHeader text="Education" />
        <p className="text-header-item">ADVERTISING MANAGER</p>
        <p className="content-item">University of Economics & Finance
          2020-2024</p>
        <p className="text-header-item">UI/UX DESIGN</p>
        <p className="content-item">University Of Science HCM
          05/2022 - 09/2022</p>
      </Box>

    </>
  );
};
export default Education;