import SwiperArticle from "./SwiperImages.js/index.js";
import Section from "../../components/Section.js";

const Images = () => {
  return (
    <Section title={"HÌNH ẢNH"}>
      <SwiperArticle />
    </Section>
  );
};
export default Images;