import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";

const HighLight = () => {
  return (
    <>
      <Box className="highlight">
        <NameContentHeader text="Highlight" />
        <p className="content-item">Your success is my success!
        </p>
      </Box>

    </>
  );
};
export default HighLight;