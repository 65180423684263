export const VCARD_FORMAT = {
  name: "FN:",
  phone: "TEL;TYPE=work,voice:",
  email: "EMAIL:",
  url: "URL:",
  image: "PHOTO;ENCODING=b;TYPE=image/jpeg:"
};

export const BASE64_PART = 1;
export const BASE64_PARTS_LENGTH = 2;
export const FIRST_VISIT_PAGE_POSITION = 0;