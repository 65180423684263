import React from "react";
import TimeLine from "../../components/TimeLine";
import Section from "../../components/Section";
import Description from "./components/Description";

const WorkExperience = () => {
  return (
    <Section title={"KINH NGHIỆM LÀM VIỆC"}>
      <TimeLine
        title={"NGƯỜI MẪU"}
        subTitle={"HODUCVINH"}
        location={"Quận 1, Hồ Chí Minh"}
        time={"2003-2011"}
        marginBottomBase
      >
        <Description
          description={`Vào năm 2003, anh giành giải thưởng Siêu 
        mẫu Việt Nam sau đó không lâu tại cuộc thi Mr. World 2007 được 
        tổ chức tại đảo Hải Nam, Trung Quốc anh đã trở thành thí sinh giành 
        giải thưởng "Người mặc trang phục truyền thống đẹp nhất".`} />
        <Description
          description={`Bên cạnh đó, anh còn là người mẫu được biết 
        đến nhiều khi tham gia vào nhiều show diễn thời trang và đóng các quảng cáo.`} />
        <Description
          description={`Anh từng là nam người mẫu sánh vai với những người đẹp thế giới trong 
        cuộc thi sắc đẹp trong nước như Cuộc thi Hoa hậu Hoàn Vũ năm 2008`} />
        <Description
          description={"Năm 2008, đoạt giải gương mặt người mẫu của năm cùng siêu mẫu Võ Hoàng Yến"} />
        <Description
          description={`Năm 2011, được UNESCO Việt Nam trao tặng
           danh hiệu người mẫu có nhiều cống hiến nhất cho nghành thời trang Việt Nam`} />
      </TimeLine>
      <TimeLine
        title={"HOẠT ĐỘNG SÀN DIỄN"}
        subTitle={"HODUCVINH"}
        location={"Quận 1, Hồ Chí Minh"}
        time={"2016-2023"}
      >
        <Description
          description={`Đầu năm 2016, Hồ Đức Vĩnh tái xuất 
        showbiz thông qua các hoạt động sàn diễn.`} />
        <Description
          description={`Từng là người hướng dẫn kỹ năng catwalk, tạo dáng trước ống 
          kính cho Tân Miss Universe Vietnam 2023 Bùi Quỳnh Hoa, 
          Hồ Đức Vĩnh dành cho người đẹp gốc Hà Nội nhiều lời khen ngợi.`} />
      </TimeLine>

    </Section>
  );
};

export default WorkExperience;