import { Typography } from "@mui/material";
import React from "react";

const SubTittleText = ({ sx, children, ...props }) => {
  return (
    <Typography sx={{
      fontSize: "0.8rem",
      color: "#ccc",
      fontWeight: "500",
      ...sx
    }} {...props}>{children}</Typography>
  );
};

export default SubTittleText;