import React from "react";
import styles from "assets/css/bussiness/template1/hexagon.module.css";
const Background = () => {
  return (
    <div className={styles["r-hex"]}>
      <div className={styles["r-hex-inner"]}></div>
    </div>
  );
};

export default Background;