/* eslint-disable max-len */
import { Typography } from "@mui/material";
import React from "react";

const Description = ({ description }) => {
  return (
    <Typography variant="content" component={"li"}>
      {description}
    </Typography>
  );
};

export default Description;