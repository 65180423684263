/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cover from "./section/cover";
import About from "./section/about";
import CeoStatment from "./section/ceoStatment";
import CompanyHistory from "./section/companyHistory";
import Mission from "./section/mission";
import Vision from "./section/vision";
import CreativeTeam from "./section/creativeTeam";
import Service from "./section/service";
import "assets/scss/business-template2.scss";
import WhyChooseUs from "./section/whyChooseUs";
import Activity from "./section/activity";
import HightLight from "./section/hightlight";
import Video from "./section/video";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Avatar from "assets/images/business_HaoHanh/Avatar.jpg";
import { convertImageFileToBase64, convertImageUrlToBase64 } from "utils/Image";
const Template2HaoHanh = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f4cf55"
      }
    }
  });
  const [imageToSaveContact, setImageToSaveContact] = useState("");
  document.documentElement.style.setProperty(
    "--bussiness-template-2-primary-color",
    "linear-gradient(180deg, #F9A825 2.28%, #F9D34E 21.79%, #FCEB8C 35.78%, #FFF9A7 45.11%, #F1D06E 54.44%, #F8A92F 70.13%, #D1841E 83.7%)");
  document.documentElement.style.setProperty(
    "--bussiness-template-2-primary-simple-color",
    "#f4cf55");

  useEffect(() => {
    const getImageToSaveContact = async () => {
      const imageBase64 = await convertImageUrlToBase64(Avatar);
      setImageToSaveContact(imageBase64);
    };
    getImageToSaveContact();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="business-template2">
        <Cover />
        <Box sx={{
          width: "100vw",
          minHeight: "100vh",
          padding: "24px",
          marginTop: "-1px",
          background: "#fff"
        }}
        >
          <HightLight />
          <Activity />
          <About />
          <WhyChooseUs />
          <CompanyHistory />
          <Mission />
          <Vision />
          <Service />
          <Video />
          <SaveContactButton contact={{
            name: "HAO HANH",
            email: "haohanhrd@gmail.com",
            phone: "093 320 48 68",
            url: "www.haohanh.com.vn",
            image: imageToSaveContact
          }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Template2HaoHanh;
