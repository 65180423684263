
import Avatar from "assets/images/Avatar.png";

const HeaderAvartar = () => {
  return (
    <>
      <img style={{
        maxWidth: "100vw",
        width: "100vw",
        height: "auto"
      }} src={Avatar} alt="avatar" />

    </>
  );
};
export default HeaderAvartar;