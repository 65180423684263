/* eslint-disable max-len */
import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

export default function TimelineTree() {
  return (
    <Timeline
      sx={{
        width: "100%",
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.1,
        },
        fontSize: "0.9rem"
      }}
    >
      <TimelineItem style={{ fontSize: "0.9em" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="left"
          variant="body2"
          fontWeight="bold"
          color="black"
        >
          2009-2011
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <FastfoodIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2, m: "auto 0" }} >
          <Typography variant="h8" component="span" fontWeight="bold" textTransform="none"
            color="black">
            Sale Engineer and Sale Manager • BMT Contruction JSC
          </Typography>
          {/* <Typography>Because you need strength</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="left"
          variant="body2"
          fontWeight="bold"
          color="black"
        >
          2010–2019
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "10px", px: 2 }}>
          <Typography variant="h8" component="span" fontWeight="bold"
            color="black">
            CEO • BMT North Contruction JSC
          </Typography>
          <Typography style={{ fontSize: "0.8rem", textTransform: "none" }}>- Made the first 5 highways in Vietnam.</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="left"
          variant="body2"
          fontWeight="bold"
          color="black"
        >
          2019–now
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <HotelIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ px: 2 }} >
          <Typography variant="h8" component="span" fontWeight="bold"
            color="black" >
            CEO• Lagom Viet Nam
          </Typography>
          <Typography style={{ fontSize: "0.8rem", textTransform: "none" }}>- Collecting paper, plastic, aluminum food containers at nearly 6000 schools, shops, factories, supermarkets to create recycled products; by 2030 to recycle 12,000 tons of plastic waste, reduce deforestation by 30 hectares per year.</Typography>
          <Typography style={{ fontSize: "0.8rem", textTransform: "none" }}> - Recycling asphalt pavement from reclaimed asphalt pavement and with plastic bags ; capacity 200,000 tons per year, reduce 100,000 tons of aggregate, 10,000 tons of asphalt, 1000 tons of plastic waste and 3200 tons CO2-eq.</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          fontWeight="bold"
          color="black"
        >
          2020–now
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
          <TimelineDot color="secondary">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0", py: "10px", px: 2 }}
          align="right"
          variant="body2"
          fontWeight="bold"
          color="black">
          <Typography variant="h8" component="span">
            Teacher • High school
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}