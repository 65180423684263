import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import { ReactComponent as Badminton } from "assets/images/Badminton.svg";
import { ReactComponent as Runner } from "assets/images/Runner.svg";
import { ReactComponent as Soccer } from "assets/images/Soccer.svg";
import { ReactComponent as Bowling } from "assets/images/Bowling.svg";
import { useTheme } from "@mui/material/styles";
import Interest from "./components/Interest.js";
import { ICON_SIZE } from "../constants.js";

const Interests = () => {
  const theme = useTheme();
  return (
    <>
      <Box className="interests">
        <NameContentHeader text="Sở thích" />
        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          marginRight: "-2rem",
          marginBottom: "-1rem",
          justifyContent: "center"
        }}>
          <Interest
            icon={<Soccer fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
            interest={"Bóng đá"}
          />
          <Interest
            icon={<Runner fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
            interest={"Chạy bộ"}
          />
          <Interest
            icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
            interest={"Cầu lông"}
          />
          <Interest
            icon={<Bowling fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
            interest={"Bowling"}
          />
        </Box>
      </Box >

    </>
  );
};
export default Interests;