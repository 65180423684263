import { Box } from "@mui/system";

const ItemInfoHeader = ({ value, right }) => {
  return (
    <>
      <Box
        style={{
          [right ? "marginLeft" : "marginRight"]: "20px",
          backgroundColor: right ? "#FBC02D" : "#81D4FA"
        }}
        className="item-info-header"
      >
        {value}
      </Box>
    </>
  );
};
export default ItemInfoHeader;