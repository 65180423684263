import { styled } from "@mui/material/styles";
import LinearProgress,
{ linearProgressClasses } from "@mui/material/LinearProgress";
import { Box } from "@mui/system";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#F9A825" : "#308fe8",
  },
}));

const ExpertiseBar = ({ value }) => {
  return (
    <>
      <Box className="expertise-bar-item">
        <BorderLinearProgress className="item" variant="determinate" value={value} />
      </Box>
    </>
  );
};
export default ExpertiseBar;