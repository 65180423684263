const FONT_PRIMARY = "Rasa, serif";

const typography = (palette) => {
  return {
    fontFamily: FONT_PRIMARY,
    h1: {
      color: palette.primary.contrastText,
      "@media (min-height: 800px) and (max-width: 399px)": {
        fontSize: "2.6rem",
      },
      "@media (min-height: 800px) and (min-width: 400px)": {
        fontSize: "3rem",
      },
      "@media (min-height: 600px) and (max-height: 799px)": {
        fontSize: "2rem",
      },
      fontWeight: "bold",

    },
    h2: {
      color: palette.primary.contrastText,
      fontSize: "1.4rem",
      "@media (min-height: 600px) and (max-height: 799px)": {
        fontSize: "1rem",
      },
      "@media (min-height: 800px) and (max-width: 399px)": {
        fontSize: "1.2rem",
      },
      "@media (min-height: 800px) and (min-width: 400px)": {
        fontSize: "1.4rem",
      },
    },
    title: {
      color: palette.primary.main,

      fontSize: "1.5rem",
      fontWeight: "bold"
    },
    subtitle1: {
      color: palette.primary.main,
      "@media (min-width: 300px) and (max-width: 399px)": {
        fontSize: "1rem",
      },
      "@media (min-width: 400px)": {
        fontSize: "1.4rem",
      },
      fontWeight: "bold",
    },
    subtitle2: {
      color: palette.primary.main,
      "@media (min-width: 300px) and (max-width: 399px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width: 400px)": {
        fontSize: "1.2rem",
      },
      fontWeight: "bold",
    },
    content: {
      color: palette.primary.main,
      fontSize: "1rem"
    }
  };
};

export default typography;
