const Content = ({ listContent }) => {
  return (
    <>
      {listContent &&
        listContent.map((item, index) => (
          <>
            <div key={index} className="year">{item.year}</div>
            {item.content.map((subItem, index) => (
              <div key={index}>{subItem}</div>
            ))}
            <br/>
          </>
        ))}
    </>
  );
};

export default Content;