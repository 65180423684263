// import Avatar from "assets/images/Avatar.png";

import { Box } from "@mui/material";
import BackgroundImage from "assets/images/persional_CaoQuangTien/BackgroundHeader.jpg";
const HeaderAvartar = () => {
  return (
    <>
      <Box style={{
        background: "black", width: "100%", height: "35vh", overflow: "hidden", textAlign: "center"
      }}>
        <img style={{
          display: "inline-block",
          width: "100%",
          height: "100%",
          objectFit: "cover"
        }} src={BackgroundImage} alt="avatar" />
      </Box>

    </>
  );
};
export default HeaderAvartar;