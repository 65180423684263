/* eslint-disable max-len */
import React from "react";
import MissionIttem from "./components/MissionIttem";
import Section from "../../components/Section";
import HouseIcon from "assets/images/HouseIcon";

const Mission = () => {
  return (
    <Section title={"Our Mission"}>
      <MissionIttem
        paddingBottomBase
        icon={<HouseIcon />}
        title={"Mission 1"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MissionIttem
        paddingBottomBase
        icon={<HouseIcon />}
        title={"Mission 2"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MissionIttem
        icon={<HouseIcon />}
        title={"Mission 3"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
    </Section>
  );
};

export default Mission;