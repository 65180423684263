/* eslint-disable max-len */
import React from "react";
import MissionIttem from "../mission/components/MissionIttem";
import HouseIcon from "assets/images/HouseIcon";
import Section from "../../components/Section";

const Vision = () => {
  return (
    <Section title={"Our Vision"}>
      <MissionIttem
        paddingBottomBase
        icon={<HouseIcon />}
        boxShadowColor={"#56CCF2"}
        title={"Vision 1"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MissionIttem
        paddingBottomBase
        icon={<HouseIcon />}
        boxShadowColor={"#FF7F00"}
        title={"Vision 2"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MissionIttem
        icon={<HouseIcon />}
        boxShadowColor={"#FFCA06"}
        title={"Vision 3"}
        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
    </Section>
  );
};

export default Vision;