import React from "react";
import Section from "../../components/Section";
import Language from "./components/Language";

const Languages = () => {
  return (
    <Section title={"NGÔN NGỮ"}>
      <Language language={"VIETNAMESE"} description={"Tiếng mẹ đẻ"} />
      <Language language={"ENGLISH"} description={"Trình độ cao"} />
    </Section>
  );
};

export default Languages;