import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import video1 from "assets/video/HoDucVinh/activity_recap.mp4";
import video2 from "assets/video/HoDucVinh/diamond_recap.mp4";
import video3 from "assets/video/HoDucVinh/marathon_dreamcup.mp4";
import video4 from "assets/video/HoDucVinh/marathon_recap.mp4";

import Section from "../../components/Section";
import { Box } from "@mui/material";
import Button from "components/Button";

const images = [
  {
    label: "TVC Lagom",
    video: video2,
  },
  {
    label: "TVC Lagom",
    video: video3,
  },
  {
    label: "TVC Lagom",
    video: video4,
  },
  {
    label: "Tái chế rác nhựa thành bàn ghế",
    video: video1,
  },
];

const Video = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Section title={"VIDEO"}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 2,
          bgcolor: "background.default"
        }}
      >
      </Paper>
      <Box sx={{ background: "white", width: "98%", margin: "0 auto" }}>
        <video src={images[activeStep].video} style={{ width: "100%", height: "100%" }} controls />
      </Box>

      < MobileStepper
        steps={maxSteps}
        style={{ width: "98%", margin: "0 auto" }}
        position="static"
        activeStep={activeStep}
        nextButton={
          < Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )
            }
          </Button >
        }
        backButton={
          < Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )
            }
            Back
          </Button >
        }
      />
    </Section>
  );
};

export default Video;