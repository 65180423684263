import { alpha } from "@mui/material";

const styleFocusedInputOutlined = (color, theme) => {
  if (theme) {
    return {
      borderColor: theme.palette[color].main,
      boxShadow: `0 0px 16px 0 ${alpha(theme.palette[color].main, 0.24)}`
    };
  }
};
export default function Input(theme) {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-colorPrimary.Mui-focused": {
            color: theme.palette.primary.dark
          },
          "&.MuiFormLabel-colorPrimary.Mui-focused.Mui-error": {
            color: theme.palette.error.main
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled },
          },
          "&.MuiInputLabel-root": {
            color: "red"
          }
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
            borderRadius: "8px",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          "&:hover": {
            backgroundColor: theme.palette.grey[500_16],
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[500_80],
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.hover,
            },
          },
          "&.Mui-focused.MuiInputBase-colorPrimary": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("primary", theme),
            }
          },
          "&.Mui-focused.MuiInputBase-colorSecondary": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("secondary", theme)
            },
          },
          "&.Mui-focused.MuiInputBase-colorError": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("error", theme)
            },
          },
          "&.Mui-focused.MuiInputBase-colorSuccess": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("success", theme)
            },
          },
          "&.Mui-focused.MuiInputBase-colorInfo": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("info", theme)
            },
          },
          "&.Mui-focused.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...styleFocusedInputOutlined("error", theme)
            },
          },
        },
      },
    }
  };
}