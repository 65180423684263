import NameContentHeader from "../NameContentHeader";
import { Box } from "@mui/system";
import Data from "./Content";

const Contents = [
  {
    year: "2020",
    content: ["O'Clock", "Grande École du Numérique", "FullStack web dev"]
  },
  {
    year: "2022",
    content: ["ESG Aix", "BTS Negociation & Relation Client"]
  },
];

const Education = () => {
  return (
    <Box className="education">
      <NameContentHeader text="Education" />
      <p className="content-item">
        <Data listContent={Contents} />
      </p>
    </Box>
  );
};

export default Education;
