import React from "react";

const ContentEducation = ({ listContent }) => {
  return (
    <div>
      {listContent &&
        listContent.map((item) => (
          <>
            <div className="content-title" key={item.year}>
              <div className="content-title-text">{item.title}</div>
              {item?.year ? <div className="content-title-year">{item.year}</div> : null}

            </div>
            <div className="content-item">
              - {item.content}
            </div>
          </>

        ))}
    </div>
  );
};

export default ContentEducation;