import React from "react";
import { ReactComponent as HexagonSvg } from "assets/images/Hexagon.svg";
import { Box } from "@mui/material";
const IconWithHexagonBackground = ({ color, icon }) => {
  return (
    <Box width={"50"} height={"50"} sx={{ position: "relative" }}>
      <HexagonSvg fill={color} width={"50"} height={"50"} />
      <Box sx={{
        position: "absolute", top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}>
        {icon}
      </Box>
    </Box>
  );
};

export default IconWithHexagonBackground;