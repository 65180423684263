import { Stack } from "@mui/material";
import Button from "components/Button";
import Input from "components/Input";
import React from "react";

const Components = () => {
  return (
    <Stack spacing={2} p="1rem">
      <Stack spacing={2} direction="row">
        <Button variant="contained">Contained</Button>
        <Button variant="outlined">Outlined</Button>
        <Button variant="text">Text</Button>
      </Stack>
      <Stack spacing={2} direction="row">
        <Button disabled variant="contained">Contained</Button>
        <Button disabled variant="outlined">Outlined</Button>
        <Button disabled variant="text">Text</Button>
      </Stack>
      <Stack spacing={2} direction="row">
        <Button color="secondary" variant="contained">Contained</Button>
        <Button color="secondary" variant="outlined">Outlined</Button>
        <Button color="secondary" variant="text">Text</Button>
      </Stack>
      <Stack spacing={2} direction="row">
        <Input
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
        />
        <Input
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
          variant="filled"
        />
        <Input
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
      </Stack>
      <Stack spacing={2} direction="row">
        <Input
          disabled
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
        />
        <Input
          disabled
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
          variant="filled"
        />
        <Input
          disabled
          required
          id="outlined-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
      </Stack>
    </Stack>
  );
};

export default Components;