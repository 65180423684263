import { Typography } from "@mui/material";
import React from "react";
const ContentText = ({ children, sx, ...props }) => {
  return (
    <Typography component={"span"} sx={{ color: "white", fontSize: "0.8rem", ...sx }}{...props}>
      {children}
    </Typography>
  );
};

export default ContentText;