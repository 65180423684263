import { Box, Typography } from "@mui/material";

export const ContentHeader = () => {
  return (
    <>
      <Box
        className="header-template3-persional">
        <Typography className="content-header-name">Thong Trung Le</Typography>

        <Box className="box-content-header-job">
          <Typography className="content-header-job">FOUNDER & CEO</Typography>
        </Box>
        <div className="bar-under-name"></div>
      </Box>
    </>
  );
};