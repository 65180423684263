import React, { useEffect } from "react";
import HeaderAvartar from "./components/HeaderAvartar";
import { ContentHeader } from "./components/ContentHeader";
import { Box, ThemeProvider } from "@mui/material";
import Contact from "./components/Contact";
import Introduce from "./components/Introduce";
import "assets/scss/persional-template1.scss";
import Expertise from "./components/Expertise";
import WorkExperience from "./components/WorkExperience";
import Interests from "./components/Interests";
import HighLight from "./components/HighLight";
import Video from "./components/Video";
import MyImage from "./components/MyImage";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import getTheme from "./mui-theme";
// import avatar from "assets/images/persional_CaoQuangTien/Kien - Tien Cao.bmp";
// import { getImageToSaveContact } from "utils/convertImageToBase64";

const palette = {
  primary: {
    main: "#000",
    contrastText: "#fff"
  }
};

const Template1_CaoQuangTien = () => {
  // const [setImageToSaveContact] = useState("");

  useEffect(() => {
    document.title = "Cao Quang Tien";
    // getImageToSaveContact(avatar, setImageToSaveContact);
  }, []);

  return (
    <ThemeProvider theme={getTheme({ palette })}>
      <Box className="persional-template1" >
        <Box style={{
          height: "100vh", width: "100%"
        }}>
          < HeaderAvartar />
          <ContentHeader />
          <Contact />
        </Box>

        <Introduce />
        <HighLight />
        <Expertise />
        <Video />
        <MyImage />
        <WorkExperience />
        <Interests />

      </Box >
      <SaveContactButton contact={{
        name: "Cao Quang Tiến",
        email: "tiencq@beetech.com.vn",
        phone: "0911123155",
        // image: imageToSaveContact
      }} />
    </ThemeProvider>
  );
};

export default Template1_CaoQuangTien;