/* eslint-disable max-len */
import { Box } from "@mui/system";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactItem from "./components/ContactItem";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/Language";
export const Contact = () => {
  return (
    <Box sx={{
      "@media (min-height: 300px) and (max-height: 799px)": {
        display: "none"
      }
    }}>
      <ContactItem
        marginBottomBase
        icon={<LocalPhoneIcon sx={{ color: "#F5F5F5" }} />}
        info={"+84 349 822 717"}
      />
      <ContactItem
        marginBottomBase
        icon={<EmailIcon sx={{ color: "#F5F5F5" }} />}
        info={"info@beetech.com.vn"}
      />
      <ContactItem
        marginBottomBase
        icon={<WebsiteIcon sx={{ color: "#F5F5F5" }} />}
        info={"www.beetech.com.vn"}
      />
      <ContactItem
        sx={{
          "@media (max-height: 899px)": {
            display: "none"
          }
        }}
        icon={<LocationOnIcon sx={{ color: "#F5F5F5" }} />}
        info={"30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc City, HCMC Vietnam"}
      />
    </Box>
  );
};
