import { Box, Typography } from "@mui/material";
import React from "react";

const Interest = ({ icon, interest }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} marginRight={"2rem"} marginBottom={"1rem"}>
      {icon}
      <Typography marginTop="1rem" variant="content">{interest}</Typography>
    </Box>
  );
};

export default Interest;