import React from "react";
import TimeLine from "../../components/TimeLine";
import Section from "../../components/Section";
import Description from "./components/Description";

const WorkExperience = () => {
  return (
    <Section title={"WORK EXPERIENCE"}>
      <TimeLine
        title={"FRONTEND DEVELOPER"}
        subTitle={"BEETECH"}
        location={"Thu Duc District, Ho Chi Minh"}
        time={"02/2021-09/2022"}
        marginBottomBase
      >
        <Description />
        <Description />
        <Description />
      </TimeLine>
      <TimeLine
        title={"FRONTEND DEVELOPER"}
        subTitle={"BEETECH"}
        location={"Thu Duc District, Ho Chi Minh"}
        time={"02/2021-09/2022"}
      >
        <Description />
        <Description />
        <Description />
      </TimeLine>
    </Section>
  );
};

export default WorkExperience;