import { Box } from "@mui/material";
import React from "react";
import ContentText from "./text/ContentText";
import CheckMark from "assets/images/CheckMark";

const CheckListItem = ({ content }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
      <CheckMark color="var(--bussiness-template-2-primary-simple-color)" width="30px" height="30px"/>
      <ContentText sx={{ marginLeft: "1rem" }}>
        {content}
      </ContentText>

      <ContentText
      />
    </Box>
  );
};

export default CheckListItem;