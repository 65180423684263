/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  logo: "",
  avatar: "",
  aboutCompay: "",
  whyChooseUs: {
    content: "",
    subContent: [],
  },
  mission: [],
  vision: [],
  companyHistory: [],
  team: [],
  service: [],
  contactUs: {
    address: "",
    phone: "",
    email: "",
    website: ""
  }
};

export const businessProfile = createSlice({
  name: "business-profile",
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.name = action.payload.name;
      state.logo = action.payload.logo;
      state.avatar = action.payload.avatar;
      state.whyChooseUs = action.payload.whyChooseUs;
      state.mission = action.payload.mission;
      state.vision = action.payload.vision;
      state.companyHistory = action.payload.companyHistory;
      state.team = action.payload.team;
      state.service = action.payload.service;
      state.contactUs = action.payload.contactUs;
    },
  },
});

export const { setInfo } = businessProfile.actions;

export default businessProfile.reducer;