import React from "react";
import { Box, Typography } from "@mui/material";

const Skill = ({ skill }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="content" component={"li"}>{skill}</Typography>
    </Box>
  );
};

export default Skill;