import { Box, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import "assets/scss/personal-template3.scss";
import getTheme from "./mui-theme";
import WorkExperience from "./sections/work-experience";
import Expertise from "./sections/expertise";
import Languages from "./sections/languages";
import Interests from "./sections/interests";
import Cover from "./sections/cover";
import Activity from "./sections/activity";
import Video from "./sections/video";
import HighLight from "./sections/highlight";
import Article from "./sections/article";
import Images from "./sections/images";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import avatar_HoDucVinh from "assets/images/HoDucVinh/avatar_HoDucVinh.jpg";
import { getImageToSaveContact } from "utils/convertImageToBase64";

const palette = {
  primary: {
    main: "#000",
    contrastText: "#fff"
  }
};
const Template3_HoDucVinh = () => {

  const [imageToSaveContact, setImageToSaveContact] = useState("");

  useEffect(() => {
    document.title = "Ho Duc Vinh";
    getImageToSaveContact(avatar_HoDucVinh, setImageToSaveContact);
  }, []);

  return (
    <ThemeProvider theme={getTheme({ palette })}>
      <Box className="personal-template3">
        <Cover />
        <Box className="section-container">
          <HighLight />
          <WorkExperience />
          <Expertise />
          <Article />
          <Images />
          <Video />
          <Activity />
          <Languages />
          <Interests />
          <SaveContactButton contact={{
            name: "Ho Duc Vinh",
            email: "admin@diamondentertainment.vn",
            phone: "0906627973",
            url: "https://diamondentertainment.vn",
            image: imageToSaveContact
          }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Template3_HoDucVinh;