import React, { useState } from "react";
import HeaderAvartar from "./components/HeaderAvartar";
import { Box } from "@mui/material";
import "assets/scss/persional-template3.scss";
import WorkExperience from "./components/WorkExperience";
import Interests from "./components/Interests";
import Education from "./Education";
import SwipeableTextMobileStepper from "./components/SwipeableTextMobileStepper";
import Video from "./components/Video";
import xamBackground from "assets/images/persional_ThongTrungLe/xam-background.png";
import camBackground from "assets/images/persional_ThongTrungLe/cam-background.png";
import xanhbienBackground from "assets/images/persional_ThongTrungLe/xanhbien-background.png";
import HighLights from "./components/Highlights";
import Activity from "./components/Activity";
import About from "./components/About";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import Avatar from "assets/images/persional_ThongTrungLe/thongtrungle-full.jpeg";
import { getImageToSaveContact } from "utils/convertImageToBase64";

const Template3 = () => {
  const [background, setBackground] = useState("");
  const [imageBackground, setImageBackground] = useState("");
  const [imageToSaveContact, setImageToSaveContact] = useState("");

  const checkColor = () => {
    const url = new URL(window.location.href);
    const color = url.searchParams.get("color");

    switch (color) {
      case "xanhbien":
        setBackground(`linear-gradient(90deg, #56B3DA 0.22%, #56A6EF 22.33%, #8CC0FC 38.2%, 
          #A7DFFF 48.77%, #6ECAF1 59.35%, #2DD6FB 77.13%, #1E91D1 92.52%)`);
        setImageBackground(xanhbienBackground);
        return;
      case "cam":
        setBackground(`linear-gradient(to right,
          #F9A825, #F9D34E, #FCEB8C, #FFF9A7, #F1D06E, #FBC02D, #D1841E)`);
        setImageBackground(camBackground);
        break;
      case "xam":
        setBackground("linear-gradient(0deg, #C4C4C4 16.07%, #F2F3F5 85.99%)");
        setImageBackground(xamBackground);
        return;
      default:
        setBackground(`linear-gradient(to right,
          #FBC02D, #F9D34E, #FCEB8C, #FFF9A7, #F1D06E, #F8A92F, #FDD835)`);
    }
  };

  // Call checkColor
  useState(() => {
    document.title = "ThongTrungLe";
    checkColor();
    getImageToSaveContact(Avatar, setImageToSaveContact);
  }, []);

  return (
    <Box className="template3-persional" style={{ background: background }} >
      <HeaderAvartar imageBackground={imageBackground} />

      <Box className="template3-persional-custom-item" >
        <div className="line-space"></div>
        <HighLights background={background} />
        <div className="line-space"></div>
        <Activity background={background} />
        <div className="line-space"></div>
        <About background={background} />
        <div className="line-space"></div>
        <SwipeableTextMobileStepper background={background} />
        <div className="line-space"></div>
        <Education background={background} />
        <div className="line-space"></div>
        <WorkExperience background={background} />
        <div className="line-space"></div>
        <Video background={background} />
        <div className="line-space"></div>
        <Interests background={background} />
        <SaveContactButton contact={{
          name: "Thong Trung Le",
          email: "thonglt@lagomvietnam.org",
          phone: "+84 916 68 60 68",
          url: "lagomvietnam.org",
          image: imageToSaveContact
        }} />
      </Box>

    </Box>
  );
};

export default Template3;