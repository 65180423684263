import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import ItemInterest from "./ItemInterest.js";
import Badminton from "assets/images/icons8-badminton-50.png";
import Traveler from "assets/images/icons8-traveler-50.png";
import Music from "assets/images/icons8-music-50.png";
import icon from "assets/images/icons8-sup-48.png";

const Interests = ({ background }) => {
  return (
    <>
      <Box className="interests" style={{ background: background }}>
        <NameContentHeader text="Interests" icon={icon} />
        <Box className="interest-content" style={{ marginTop: "10px" }}>
          <ItemInterest
            icon={<img src={Badminton} className="image-interest" alt="Description of the image" />}
            contentIcon={"Badminton"} />
          <ItemInterest
            icon={<img src={Traveler} className="image-interest" alt="Description of the image" />}
            contentIcon={"Traveler"} />
          <ItemInterest
            icon={<img src={Music} className="image-interest" alt="Description of the image" />}
            contentIcon={"Music"} />
        </Box>
      </Box >

    </>
  );
};
export default Interests;