/* eslint-disable no-unused-vars */
import { Box, Stack } from "@mui/material";
import React from "react";
import Section from "../../components/Section";
import { DataService } from "../service/dataService.js";
import ListItemWithIcon from "./components/ListItemWithIcon";
import IconWithCircleBackground from "./components/IconWithCircleBackground";

const Service = () => {
  return (
    <Box>
      <Section title={"OUR SERVICE"}>
        {DataService.map((data, index) => {
          return <ListItemWithIcon
            icon={<IconWithCircleBackground icon={data.icon} color={"var(--bussiness-template-2-primary-color)"} />}
            content={data.context}
            title={data.title}
            marginBottomBase={index !== DataService.length - 1}
          />;
        })}
      </Section>
    </Box>
  );
};

export default Service;
