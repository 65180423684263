/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import React from "react";
import Background from "./Background";
import Avatar from "./Avatar";
import SubBackground from "./SubBackground";
import TriangleBackground from "./TriangleBackground";

const HexagonAvatar = () => {
  return (
    <Box sx={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
      height: "fit-content",
      marginTop: "4rem"
    }}>
      <Background />
      <SubBackground />
      <Avatar />
      <TriangleBackground />
    </Box>
  );
};

export default HexagonAvatar;