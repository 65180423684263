import React from "react";
import LogoBeetech from "assets/images/LogoBeetech.png";
import { Box } from "@mui/material";
const Logo = () => {
  return (
    <Box sx={{
      top: "1rem",
      left: "1rem"
    }}>
      <img src={LogoBeetech} width={"200px"}/>
    </Box>
  );
};

export default Logo;