/* eslint-disable max-len */
import { Box, Typography } from "@mui/material";
import React from "react";
import TriangleBackgroundImg from "assets/images/TriangleBackground.png";
import Website from "./components/Website";
import { Contact } from "./components/Contact";
import Slogan from "./components/Slogan";
const TriangleBackground = () => {
  return (
    <Box sx={{
      width: "100vw",
      position: "absolute",
      opacity: "0.9",
      top: "50%",
      height: "50vh"
    }}>
      <img src={TriangleBackgroundImg} width={"100%"} />
      <Box sx={{
        width: "100%",
        padding: "0 2rem",
        position: "absolute",
        top: "12rem",
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center",
        "@media (min-height: 300px) and (max-height: 849px)": {
          top: "10rem",
        },
        "@media (min-height: 850px)": {
          top: "12rem",
        }
      }}>
        <Typography color={"#fff"} sx={{ fontSize: "2rem" }}>CORPORATE</Typography>
        <Typography color={"#fff"} sx={{
          fontSize: "2.6rem",
          color: "var(--primary-color)",
          fontWeight: "bold"
        }}>BROCHURE</Typography>
        <Slogan />
        <Website />
        <Contact />
      </Box>
    </Box>
  );
};

export default TriangleBackground;