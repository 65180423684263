/* eslint-disable max-len */
import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import activity1 from "assets/images/business_HaoHanh/actitvity/activity1.jpg";
import activity2 from "assets/images/business_HaoHanh/actitvity/activity2.png";
// import SketchingImg from "assets/images/SketchingImg.png";

const steps = [
  {
    label: "Dragon Fruit GLOBALG.A.P. for export to the EU",
    description: "Hao Hanh Company is proud to be a pioneer in the preservation and export of fresh dragon fruit that meets GLOBALG.A.P. standards by sea to the demanding European market",
    img: activity2
  },
  {
    label: "Ensuring Quality and Timely Delivery for Your Food Products",
    description: "Hao Hanh Company has a manufacturing and packaging plant that meets the HACCP and FDA standards of the United States. Depending on the order, we can transport the goods by sea or air to meet the time and requirements of customers.",
    img: activity1
  },
];

export default function SwipeActivity() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{steps[activeStep].label}</Typography>
      </Paper>

      <Box sx={{ height: "auto", width: "100%", background: "white", padding: "10px 10px", textTransform: "none" }}>
        <img style={{ height: "auto", width: "100%", background: "white" }} src={steps[activeStep].img} />
        {steps[activeStep].description}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </>
  );
}