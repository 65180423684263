/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import activity1 from "assets/images/HoDucVinh/Activity1.jpg";
import activity2 from "assets/images/HoDucVinh/Activity2.jpeg";

import SwipperItem from "./SwipperItem";
const items = [
  {
    title: "Siêu mẫu Hồ Đức Vĩnh sống ra sao sau khi rút khỏi sàn catwalk?",
    description: (
      <>
        <p>Hồ Đức Vĩnh cho biết, do tuổi tác không còn phù hợp làm người mẫu, nên anh lựa chọn hướng đi đứng phía sau, huấn luyện các người mẫu trẻ để truyền đạt kinh nghiệm cho các thế hệ tiếp theo.</p>
        <p>Tôi không còn xuất hiện trên sàn catwalk khoảng 5 năm vì tôi đã bước vào tuổi trung niên, cũng như thấy rằng mình đã cống hiến hết tuổi thanh xuân cho ngành nghề này. Tôi đã đạt được nhiều giải thưởng và có tên tuổi trong làng người mẫu.</p>
        <a href="https://dantri.com.vn/giai-tri/sieu-mau-ho-duc-vinh-song-ra-sao-sau-khi-rut-khoi-san-catwalk-20231002125609859.htm?fbclid=IwAR0-LbbOEqLxbjVUVp3o-kNXjJWpHJhGHcbIsYSH89F3NRwW0QKSXGSsZLo_aem_AUY3T-TNHV1KjrjHF0u7YHLr2n1ektuzm7dmjzeGVIBYOgU4JvkRUcKqjUpP0dWyK34" style={{ color: "black", fontStyle: "normal" }}>dantri.com
        </a>
      </>
    ),
    img: "https://cdnphoto.dantri.com.vn/MVUtohARWUfUKtV4f5DKySS09pA=/thumb_w/1020/2023/10/02/img-0783qxtx-1696225165967.jpeg"
  },
  {
    title: "Diamond Entertainment chính thức ra mắt tại Việt Nam",
    description: (
      <>
        <p>Ngày 23/4 vừa qua, lễ ra mắt công ty Diamond Entertainment đã được tổ chức thân mật với sự tham dự
          của 50 khách VIP, tại Gem Center, những định hướng cùng các dự án sắp tới cũng được hé lộ.</p>
        <a href="https://www.kinhtenews.com/2023/04/diamond-entertainment-chinh-thuc-ra-mat-tai-vietnam.html?m=1" style={{ color: "black", fontStyle: "normal" }}>doanhnhanngaynay.vn
        </a>
      </>),
    img: activity1
  },
  {
    title: "Siêu mẫu Hồ Đức Vĩnh và dàn sao Việt tổ chức giải chạy từ thiện",
    description: (
      <>
        <p>Giải chạy Marathon Dream Cup 2023 do Hội Liên hiệp Thanh niên Việt Nam TPHCM và Diamond Entertainment phối hợp tổ chức nhằm thúc đẩy tinh thần thể dục thể thao rèn luyện thể chất lẫn của cộng đồng với thông điệp “Reach Your Dream – Chinh phục ước mơ”. </p>
        <p>Tại sự kiện phát động giải chạy Marathon Dream Cup 2023, ngoài siêu mẫu Hồ Đức Vĩnh còn có sự đồng hành của nhiều nghệ sĩ như diễn viên Trương Ngọc Ánh, Á hoàng trang sức Cao Thùy Trang, người mẫu Trang Trần, stylist Trần Đạt, Á hậu Thanh Hoài, vợ chồng Kha Ly - Thanh Duy, MC Thư Đình, người đẹp Thủy Bùi, ca sĩ Minh Hoàng...</p>
        <a href="https://vietnamnet.vn/sieu-mau-ho-duc-vinh-va-dan-sao-viet-to-chuc-giai-chay-tu-thien-2157686.html?fbclid=IwAR1yxgX6i5kncPq483uxH9CyEcL0ooHstQABNyd6Q8k95S695lgxI3HsQ0w_aem_AUZtwVIOfWKwRNy2JsNSO1DwuMxwlJ-krELV0TxTBYlG1GHxL7dnsjHaOfM1iljQRfM" style={{ color: "black", fontStyle: "normal" }}>vietnamnet.vn
        </a>
      </>
    ),
    img: activity2
  },
  {
    title: "Cung đường trở thành đường chạy cho Runners của Marathon Dream Cup 2023",
    description: (
      <>
        <p>Theo thông báo của ban tổ chức, các runner sẽ cùng tranh tài ở cự ly 5km (nam, nữ) và 10km (nam, nữ). Xuất phát từ Nhà Văn hóa Thanh Niên, người tham gia sẽ có cơ hội ngắm nhìn những cung đường đẹp nhất của thành phố như Phạm Ngọc Thạch, Lê Duẩn, Nam Kỳ Khởi Nghĩa, Lý Tự Trọng, Đồng Khởi, Lê Thánh Tôn, Nguyễn Huệ…</p>
        <p>Tại sự kiện phát động giải chạy Marathon Dream Cup 2023, có sự đồng hành của nhiều nghệ sĩ như diễn viên Trương Ngọc Ánh, Á hoàng trang sức Cao Thùy Trang, người mẫu Trang Trần, stylist Trần Đạt, Á hậu Thanh Hoài, vợ chồng Kha Ly - Thanh Duy, MC Thư Đình, người đẹp Thủy Bùi, ca sĩ Minh Hoàng....</p>
        <a href="https://vtv.vn/the-thao/cung-duong-dep-nhat-tp-ho-chi-minh-tro-thanh-duong-chay-cho-runners-cua-marathon-dream-cup-2023-20230623095257109.htm?fbclid=IwAR2YQg6_eMKlnkDM_dxJQ-ZqOcDWUR18Hrw2NmqcM6rCXvhyT6gTIHEmhdQ_aem_AUabd5HGLugNWwwfWrLAnPh224lVkNSaXLe5ryHA1reRQFiNmSqnGyGvAq8e2pBobMY" style={{ color: "black", fontStyle: "normal" }}>vtv.vn
        </a>
      </>
    ),
    img: "https://vtv1.mediacdn.vn/thumb_w/640/562122370168008704/2023/6/23/3-1687488691014728502247.jpg"
  }
];
export default function SwiperActivity() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item.title} description={item.description} image={item.img} />

        </SwiperSlide>;
      })}
    </Swiper>
  );
}