import avatar_HoDucVinh from "assets/images/HoDucVinh/avatar_HoDucVinh.jpg";

export const coverStyles = ({ palette }) => {
  return {
    container: {
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff"
    },
    infoWithShapeBackgroundContainer: {
      position: "relative"
    },
    shapeBackground: {
      rectangle: {
        position: "relative",
        width: "100vw",
        height: "50vh",
        zIndex: 2,
        backgroundColor: palette.primary.main,
        "&:after": {
          content: "\"\"",
          position: "absolute",
          top: "100%",
          left: 0,
          backgroundColor: "inherit",
          paddingBottom: "50%",
          width: "100%",
          zIndex: 1,
          transformOrigin: "0 0",
          transform: "rotate(-30deg) skewX(30deg)",
        }
      },
    },
    mainInfoContainer: {
      position: "absolute",
      width: "100vw",
      top: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 3
    },
    avatarCointainer: {
      borderRadius: "100%",
      width: "50vw",
      height: "50vw",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    avatar: {
      width: "80%",
      height: "80%",
      border: "1px solid #000",
      borderRadius: "100%",
      backgroundSize: "cover",
      backgroundImage: "url('https://www.nazafu.com/images/thumbs/2018/03/ao-vest-xam-chuot-1103-61-slide-products-5abdf6fc5001c.jpg')"
    },
    avatarHoDucVinh: {
      width: "90%",
      height: "90%",
      border: "1px solid #000",
      borderRadius: "100%",
      backgroundSize: "cover",
      backgroundImage: `url(${avatar_HoDucVinh})`
    }
  };
};