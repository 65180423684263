import SwiperActivity from "./SwiperActivity.js/index.js";
import Section from "../../components/Section.js";

const Activity = () => {
  return (
    <Section title={"HOẠT ĐỘNG"}>
      <SwiperActivity />
    </Section>
  );
};
export default Activity;