/* eslint-disable max-len */
import React from "react";
import { Box, Typography } from "@mui/material";
const ContactItem = ({ icon, info, marginBottomBase, sx }) => {
  return (
    <Box display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      marginBottom={marginBottomBase && "1rem"}
      padding={"0 1rem"}
      sx={sx}
    >
      {icon}
      <Typography sx={{ marginLeft: "0.4rem" }} color={"#F5F5F5"}>{info}</Typography>
    </Box>
  );
};

export default ContactItem;