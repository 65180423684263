import React from "react";
import Section from "../../components/Section";
import TimeLine from "../../components/TimeLine";

const Education = () => {
  return (
    <Section title={"EDUCATION"}>
      <TimeLine
        title={"ADVERTISING MANAGER"}
        subTitle={"Sai Gon University "}
        location={"Thu Duc District, Ho Chi Minh"}
        time={"02/2021-09/2022"}
        marginBottomBase
      />
      <TimeLine
        title={"ADVERTISING MANAGER"}
        subTitle={"Sai Gon University "}
        location={"Thu Duc District, Ho Chi Minh"}
        time={"02/2021-09/2022"}
      />
    </Section>
  );
};

export default Education;