/* eslint-disable max-len */
import Avatar from "assets/images/persional_ThongTrungLe/thongtrungle-full.jpeg";
import Contact from "./Contact";
import { ContentHeader } from "./ContentHeader";
import { Box } from "@mui/material";

const HeaderAvartar = ({ imageBackground }) => {

  return (
    <>
      <Box height={"100vh"} style={{ background: "white" }}>
        <Box height={"50vh"}>
          {/* className="image-header" */}
          <img
            style={{
              position: "relative",
              width: "auto",
              maxWidth: "100%",
              height: "50vh",
            }}
            src={Avatar}
            alt="avatar"
          />

        </Box>

        <Box style={{ position: "relative", height: "70vh", marginTop: "-20vh", overflowX: "hidden", overflowY: "hidden" }}>
          <img
            style={{
              position: "relative",
              width: "100vw",
              height: "70vh"

            }}
            src={imageBackground}
            alt="avatar"
          />

          <Box height={"50vh"} style={{ width: "100%", position: "absolute", top: "19vh", left: "50%", transform: "translateX(-50%)" }} >
            <ContentHeader />
            <Contact />

          </Box>
        </Box>

      </Box >

    </>
  );
};
export default HeaderAvartar;