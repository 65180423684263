import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const Skill = ({ skill, percent }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="subtitle1">{skill}</Typography>
      <LinearProgress variant="determinate" value={percent} sx={{ flex: 1, marginLeft: "1rem" }} />
    </Box>
  );
};

export default Skill;