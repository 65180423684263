/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import SwipperItem from "./SwipperItem";
import binhluanbongda from "assets/images/HoDucVinh/Binhluanbongda.JPG";
import nhatochucsukien from "assets/images/HoDucVinh/Nhatochucsukien.jpg";
import tochucgiaohuubongda from "assets/images/HoDucVinh/Tochucgiaohuubongda.jpg";
import daotaonguoimau from "assets/images/HoDucVinh/Daotaonguoimau.JPG";
import thamgiathiennguyen from "assets/images/HoDucVinh/Thamgiathiennguyen.jpg";
import chutichclb from "assets/images/HoDucVinh/Chutichclbbongda.jpg";
import LeGiang from "assets/images/HoDucVinh/LeGiang.png";

const items = [
  {
    title: "Lê Giang, Quỳnh Thư dự sự kiện của Hồ Đức Vĩnh.",
    img: LeGiang
  },
  {
    title: "Chương trình Xuân yêu thương gồm các chuỗi hoạt động như Quà tết yêu thương, Nét vẽ ngày xuân, Tết hội nhập…",
    img: "https://images2.thanhnien.vn/Uploaded/thanhlongn/2023_01_16/img-1073-4274.jpeg"
  },
  {
    title: "Hoa hậu Phan Thị Mơ cùng nhiều nghệ sĩ có mặt trong tiệc của Hồ Đức Vĩnh.",
    img: "https://cdnphoto.dantri.com.vn/WJ10oc9HWoCzMwop3TJnC1cvnGw=/thumb_w/1020/2023/10/02/anh-chup-man-hinh-2023-10-02-luc-124328-1696225503123.png"
  },
  {
    title: "Siêu mẫu Hồ Đức Vĩnh người sáng lập Diamond Entertainment cùng Á hoàng Thanh Tâm trao hoa và tri ân nhà tài trợ.",
    img: "https://images2.thanhnien.vn/thumb_w/640/528068263637045248/2023/10/30/g8-16986539946481871449308.jpeg"
  },
  {
    title: "Bình luận viên bóng đá giải Worldcup 2022.",
    img: binhluanbongda
  },
  {
    title: "Nhà tổ chức sự kiện Marathon Dream cup 2023.",
    img: nhatochucsukien
  },
  {
    title: "Dàn người mẫu và MC trên thảm đỏ.",
    img: "https://images2.thanhnien.vn/thumb_w/640/528068263637045248/2023/10/30/g9-16986539946821709999041.jpeg"
  },
  {
    title: "Hồ Đức Vĩnh phát động giải Golf Dream Cup 2023 dự kiến diễn ra vào tháng 12.",
    img: "https://cdnphoto.dantri.com.vn/wNDzYOE_4IPQpa9ehWmK37GqUsQ=/thumb_w/1020/2023/10/02/anh-chup-man-hinh-2023-10-02-luc-124153-1696225434721.png"
  },

  {
    title: "Tổ chức giao hữu các CLB và cầu thủ nổi tiếng trong và ngoài nước.",
    img: tochucgiaohuubongda
  },
  {
    title: "Đào tạo người mẫu và chuyên tư vấn kỹ năng catwalk.",
    img: daotaonguoimau
  },
  {
    title: "Tham gia các hoạt động thiện nguyện.",
    img: thamgiathiennguyen
  },
  {
    title: "Chủ tịch CLB bóng đá nghệ sĩ.",
    img: chutichclb
  }
];
export default function SwiperImages() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item?.title} image={item?.img} />

        </SwiperSlide>;
      })}
    </Swiper>
  );
}