import React from "react";
import hexagonAvatarStyles from "assets/css/bussiness/template1/hexagonAvatar.module.css";
import hexagonStyles from "assets/css/bussiness/template1/hexagon.module.css";
import { Box } from "@mui/material";
const Avatar = () => {

  return (
    <Box sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      zIndex: "1000"
    }}>
      <div className={`
    ${hexagonStyles["r-hex"]} 
    ${hexagonAvatarStyles["r-hex"]} 
    ${hexagonAvatarStyles["avatar"]}`}
      >
        <div className={`
      ${hexagonStyles["r-hex-inner"]} 
      ${hexagonAvatarStyles["r-hex-inner"]} 
      ${hexagonAvatarStyles["avatar"]}`}
        >
          <div className={`
        ${hexagonStyles["r-hex-inner-2"]}  
        ${hexagonAvatarStyles["r-hex-inner-2"]} 
        ${hexagonAvatarStyles["avatar"]}`}
          >
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Avatar;