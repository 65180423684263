
const Content = ({ listContent }) => {
  return (
    <p>
      {listContent &&
        listContent.map((item, index) => (
          <>
            <div className="content-title" key={index}>{item.title}
              <div className="separator"> - </div> 
              <div className="text" key={index}>{item.content}</div>
            </div>
          </>
        ))}
    </p>
  );
};
export default Content;