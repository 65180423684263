import { Box } from "@mui/system";
import React from "react";
import { coverStyles } from "assets/js/personal-template3";
import ShapeBackground from "./components/ShapeBackground";
// import MainInfo from "./components/MainInfo";
import Contact from "./components/Contact";
import { useTheme } from "@mui/material/styles";
import Avatar from "./components/Avatar";
const Cover = () => {
  const theme = useTheme();
  const styles = coverStyles({ palette: theme.palette });
  return (
    <Box sx={styles.container}>
      <ShapeBackground />
      <Avatar />
      <Contact />
    </Box>
  );
};

export default Cover;