/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import Content from "./Content.js";

const WorkExperience = () => {
  return (
    <>
      <Box className="work-experience">
        <NameContentHeader text="Work experience" />
        <Content title={"Marketing"} listContent={listContent} />
      </Box >

    </>
  );
};
export default WorkExperience;

const listContent = [
  {
    year: "2021-2022", title: "Maicogroup Co.Ltd/Social Media",
    content: "Working environment is a real estate brokerage company, I have learned a lot about customer psychology and behavior from which to come up with appropriate strategies to close the deal as quickly as possible."
  },
  {
    year: "17-18/06/2022", title: "Cánh Cung Co.Ltd/Logicstics",
    content: "The 27th National Awarding Congress of NewImage company."
  },
  {
    year: "12-13/07/2022", title: "Cánh Cung Co.Ltd/Support Customer",
    content: "Event “Lets break through together” and Sportday of Jollibee VietNam."
  }
];