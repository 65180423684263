import {
  Box,
} from "@mui/material";
import React from "react";
import ContentText from "../../../components/text/ContentText"; 
import SubTittleText from "../../../components/text/SubTitleText"; 
import TitleText from "../../../components/text/TitleText";
const MemberItem = ({ marginBottomBase, name, jobTitle, introduction }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} marginBottom={marginBottomBase && "1rem"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box className="avatar"
          sx={{
            width: "150px",
            height: "200px",
            background: "white",
            borderRadius: "10px",
            overflow: "hidden"
          }}
        >
          <img
            src="https://product.hstatic.net/1000333436/product/ld__27__34730847168d4017b9be571d45509e23_master.jpg"
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <TitleText marginTop={"1rem"}>
          {name}
        </TitleText>
        <SubTittleText >
          {jobTitle}
        </SubTittleText>
        <Box sx={{
          height: "2px",
          background: "var(--primary-color)",
          width: "100%",
          margin: "0.8rem 0"
        }} />
      </Box>
      <ContentText sx={{ textAlign: "center" }}>
        {introduction}
      </ContentText>
    </Box>
  );
};

export default MemberItem;