import React from "react";
import Section from "../../components/Section";
import Skill from "./components/Skill";

const Expertise = () => {
  return (
    <Section title={"EXPERTISE"}>
      <Skill skill={"Figma"} percent={50} />
      <Skill skill={"Figma"} percent={80} />
      <Skill skill={"Figma"} percent={50} />
      <Skill skill={"Figma"} percent={50} />
    </Section>
  );
};

export default Expertise;