import { Box, Button } from "@mui/material";
// import video from "assets/video/A fight for education like you’ve never seen before _ UNICEF.mp4";
import NameContentHeader from "../NameContentHeader.js";
// import { autoPlay } from "react-swipeable-views-utils";
// import SwipeableViews from "react-swipeable-views";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import video1 from "assets/video/video1.mp4";
import video2 from "assets/video/video2.mp4";
import icon from "assets/images/icons8-video-50.png";

const images = [
  {
    label: "Tái chế rác nhựa thành bàn ghế",
    imgPath: video1,
  },
  {
    label: "TVC Lagom",
    imgPath: video2,
  }
];

const Video = ({ background }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };
  return (
    <Box className="work-experience" style={{ background: background }}>
      <NameContentHeader text="My video" icon={icon} />
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 2,
          background: background
        }}
      >
      </Paper>
      <Box sx={{
        background: "white", width: "98%", margin: "0 auto", borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
      }}>
        <video src={images[activeStep].imgPath} style={{
          width: "100%", height: "100%", borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px"
        }} controls />
      </Box>

      < MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          width: "98%", margin: "0 auto"
        }}
        nextButton={
          < Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )
            }
          </Button >
        }
        backButton={
          < Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )
            }
            Back
          </Button >
        }
      />

    </Box >
  );
};
export default Video;