import ItemInfoHeader from "./ItemInfoHeader";

const BoxInfoHeader = () => {
  return (
    <>
      <ItemInfoHeader value="argaudflorian@gmail.com" />
      <ItemInfoHeader value="+84 34 98 22 717" right={true} />
      <ItemInfoHeader value="https://independence.dev" />
    </>
  );
};

export default BoxInfoHeader;