/* eslint-disable max-len */
import { ICON_SIZE } from "./constants";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import AutorenewIcon from "@mui/icons-material/Autorenew";
export const DataService = [
  {
    icon: <AgricultureIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "RFID",
    context: [
      "Industry 4.0 presents a unique challenge of efficiently transforming traditional manufacturing to smart and autonomous systems."
    ],
  },
  {
    icon: <AutorenewIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "IoT & Ai",
    context: [
      "The main purpose of integrating AI, IoT with this industry is to speed up productivity, to reduce the production cost. Enhancing supply chain management, Increase the value of products."
    ],

  },
  {
    icon: <AutorenewIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "Barcode",
    context: [
      `For more than four decades, barcodes have helped companies, large and small, more efficiently and accurately identify, track, and inventory a variety of products, assets, supplies, and more.
      The invention of the bar code made things easier and more convenient in warehouse management, retail, hospital, manufacturing, logistics,... `
    ],

  },
  {
    icon: <AutorenewIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "Software, Website",
    context: [
      // eslint-disable-next-line quotes
      `We are a leading software development company that offers top-rated Software Development Services due to our vast experience, team of skilled professionals, key business insights, and dedicated working process.`
    ],

  },

];
