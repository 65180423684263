import { Box, Button, Typography } from "@mui/material";
// import video from "assets/video/A fight for education like you’ve never seen before _ UNICEF.mp4";
import NameContentHeader from "../NameContentHeader.js";
// import { autoPlay } from "react-swipeable-views-utils";
// import SwipeableViews from "react-swipeable-views";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import education from "assets/video/CaoQuangTien/Education.mp4";
import mapVideo from "assets/video/CaoQuangTien/Map_RFID.mp4";
import solution from "assets/video/CaoQuangTien/Solution.mp4";
import videoShow from "assets/video/CaoQuangTien/Video_Show.mp4";
import testDeviceVideo from "assets/video/CaoQuangTien/Go-thru.mp4";
import icon from "assets/images/icons8-video-50.png";

const items = [
  {
    title: "Giáo dục",
    video: education
  },
  {
    title: "Vẽ bản đồ",
    video: mapVideo
  },
  {
    title: "Hệ thống quét bằng RFID và hệ thống Convey.",
    video: solution
  },
  {
    title: "Quét RFID",
    video: videoShow
  },
  {
    title: "Kiểm tra thiết bị.",
    video: testDeviceVideo
  }
];
const Video = ({ background }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="work-experience" style={{ background: background }}>
      <NameContentHeader text="Video" icon={icon} />
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 1,
          bgcolor: "background.default",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <Typography margin="0 auto" fontWeight={"bold"}>{items[activeStep].title}</Typography>
      </Paper>
      <Box sx={{ background: "white", width: "100%", margin: "0 auto" }}>
        <video src={items[activeStep].video} style={{ width: "100%", height: "100%" }} controls />
      </Box>

      < MobileStepper
        steps={maxSteps}
        style={{
          width: "100%", margin: "0 auto", borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
        position="static"
        activeStep={activeStep}
        nextButton={
          < Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )
            }
          </Button >
        }
        backButton={
          < Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {
              theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )
            }
            Back
          </Button >
        }
      />

    </Box >
  );
};
export default Video;