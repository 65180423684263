/* eslint-disable max-len */
import { Box, Typography } from "@mui/material";
import React from "react";
import LineDivider from "./LineDivider";

const Slogan = () => {
  return (
    <Box sx={{
      "@media (min-height: 300px) and (max-height: 799px)": {
        display: "none"
      }
    }}>
      <Typography color={"#F5F5F5"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
      <LineDivider />
    </Box>
  );
};

export default Slogan;