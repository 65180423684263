/* eslint-disable max-len */
import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column"
    }}>
      <Button variant="contained"
        onClick={() => navigate("/bussiness/template1")}
        sx={{ marginBottom: "1rem" }}>
        Business Template1
      </Button>
      <Button variant="contained"
        onClick={() => navigate("/bussiness/template2")}
        sx={{ marginBottom: "1rem" }}>
        Business Template2
      </Button>
      <Button variant="contained" onClick={() => navigate("/business/haohanh")}
        sx={{ marginBottom: "1rem" }}>
        Business Template haohanh
      </Button>
      <Button variant="contained" onClick={() => navigate("/business/beetech")}
        sx={{ marginBottom: "1rem" }}>
        Business Template beetech
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template1")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template1
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template1/CaoQuangTien")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template1- CaoQuangTien
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template2")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template2
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/ThongTrungLe?color=xanhbien")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template ThongTrungLe
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template3")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template3
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template4")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template4
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template3/HoDucVinh")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template3- Ho Duc Vinh
      </Button>
      <Button variant="contained" onClick={() => navigate("/personal/template1/BuiThiThaiAnh")}
        sx={{ marginBottom: "1rem" }}>
        Personal Template1- Bui Thi Thai anh
      </Button>
    </Box>
  );
};

export default Profile;