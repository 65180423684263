/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import SwipperItem from "./SwipperItem";
import image1 from "assets/images/business_HaoHanh/CHONG ĐÈN TL.jpg";
import image2 from "assets/images/business_HaoHanh/FARM2.jpg";
import image3 from "assets/images/business_HaoHanh/HOA TL.jpg";
import image4 from "assets/images/business_HaoHanh/HOA TL1.jpg";
import image6 from "assets/images/business_HaoHanh/T15.jpg";
import image8 from "assets/images/business_HaoHanh/V1.jpg";
import image9 from "assets/images/business_HaoHanh/V2.jpg";
import image10 from "assets/images/business_HaoHanh/V4.jpg";
import image11 from "assets/images/business_HaoHanh/V13.jpg";

const items = [
  {
    img: image1
  },
  {
    img: image2
  },
  {
    img: image3
  },
  {
    img: image4
  },
  {
    img: image6
  },
  {

    img: image8
  },

  {

    img: image9
  },
  {
    img: image10
  },
  {

    img: image11
  }
];
export default function SwiperImages() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item?.title} image={item?.img} />

        </SwiperSlide>;
      })}
    </Swiper>
  );
}