/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import SwipperItem from "./SwipperItem";
import meetingTaiWan from "assets/images/persional_CaoQuangTien/taiwan.jpg";
import famousKorea from "assets/images/persional_CaoQuangTien/FamousKorean.jpg";
import Fiora from "assets/images/persional_CaoQuangTien/Fiora.jpg";

const items = [
  {
    title: "Meeting in Taiwan",
    img: meetingTaiWan
  },
  {
    title: "A famous MIDDLEWARE provider in Korea!",
    img: famousKorea
  },
  {
    title: "Fujikura - Check in .",
    img: Fiora
  }
];
export default function SwiperImages() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item.title} image={item.img} />

        </SwiperSlide>;
      })}
    </Swiper>
  );
}