import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import icon from "assets/images/icons8-about-50.png";

const About = ({ background }) => {
  return (
    <>
      <Box className="work-experience" style={{ background: background }}>
        <NameContentHeader text="About" icon={icon} />
        {/* <Content listContent={listContent} /> */}
        {/* <TimelineTree /> */}
        <div style={{ fontWeight: "normal", marginLeft: "10px", textTransform: "none", fontSize: "0.9em" }}>
          Lagom Vietnam was established in early 2019 as an environmental services
          entrepreneur, we provide solid waste management services including waste collection &
          operation improvement, targeting low-value waste such as Used Beverage Carton (UBC) - eg:
          Milk carton/juice packaging. We have started building our services and products to help
          businesses and local authorities to eliminate the impacts of low-value
          waste on the environment via the circular economy concept.
        </div>
      </Box >

    </>
  );
};
export default About;