import { Paper, Typography, Box } from "@mui/material";
import React from "react";

const SwipperItem = ({ title, image }) => {
  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          border: "10px",
          boxShadow: "2px black",
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
        }}
      >
        <Typography fontWeight={"bold"}>{title}</Typography>
      </Paper>

      <Box sx={{ height: "auto", width: "100%", background: "white", padding: "10px 10px", textTransform: "none" }}>
        <img style={{ height: "auto", width: "100%", background: "white" }} src={image} />
      </Box></>
  );
};

export default SwipperItem;