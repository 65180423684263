import { Box } from "@mui/material";
import React from "react";
import ContentText from "./text/ContentText";
import CheckMark from "assets/images/CheckMark";

const CheckListItem = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
      <CheckMark color="var(--primary-color)" />
      <ContentText sx={{ marginLeft: "1rem" }}>
        {"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
      </ContentText>

      <ContentText
      />
    </Box>
  );
};

export default CheckListItem;