import React from "react";
import Section from "../../components/Section";
import Skill from "./components/Skill";

const HighLight = () => {
  return (
    <Section title={"ĐIỂM NỔI BẬT"}>
      <Skill skill={"Nghệ sĩ luôn có trách nhiệm với cộng đồng."} />
      <Skill skill={`Luôn có những buổi quyên góp để ủng hộ cho những người 
      có hoàn cảnh khó khăn tại thành phố hồ Chí Minh`} />
      <Skill skill={"Có kinh nghiệm gần 20 năm trong nghành thời trang Việt Nam."} />
      <Skill skill={`Có kinh nghiệm hướng dẫn catwalk và đào tạo 
      các người mẫu đi thi các cuộc thi trong và ngoài nước.`} />
    </Section>
  );
};

export default HighLight;