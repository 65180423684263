import { Box } from "@mui/system";
import ContentEachYear from "./ContentEachYear";

const Content = ({ title, listContent }) => {
  return (
    <>
      <Box className="title">
        {title}
        <ContentEachYear listContent={listContent} />
      </Box>

    </>
  );
};
export default Content;