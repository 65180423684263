import { Box } from "@mui/material";
import React from "react";
import ContentText from "../../../components/text/ContentText"; 
import TitleText from "../../../components/text/TitleText";
const MissionIttem = ({ icon, boxShadowColor, title, content, paddingBottomBase }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} paddingBottom={paddingBottomBase && "2rem"}>
      <Box sx={{
        background: "white",
        boxShadow: `0 10px 80px ${boxShadowColor}`,
        padding: "2rem",
        borderRadius: "10px"
      }}>
        {icon}
      </Box>
      <TitleText marginTop={"1rem"}>
        {title}
      </TitleText>
      <ContentText sx={{ textAlign: "center", marginTop: "0.6rem" }}>
        {content}
      </ContentText>
    </Box>
  );
};

export default MissionIttem;