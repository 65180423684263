import { Box, ThemeProvider } from "@mui/material";
import React from "react";
import Education from "./sections/education";
import "assets/scss/personal-template4.scss";
import getTheme from "./mui-theme";
import WorkExperience from "./sections/work-experience";
import Expertise from "./sections/expertise";
import Languages from "./sections/languages";
import Interests from "./sections/interests";
import Cover from "./sections/cover";
// import CurvedShape from "./components/CurvedShape";
const palette = {
  primary: {
    main: "#000",
    contrastText: "#fff"
  }
};
const Template4 = () => {
  return (
    <ThemeProvider theme={getTheme({ palette })}>
      <Box className="personal-template4">
        <Cover />
        {/* <CurvedShape /> */}
        <Box className="section-container">
          <Education />
          <Expertise />
          <WorkExperience />
          <Languages />
          <Interests />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Template4;