import React from "react";
import { useTheme } from "@mui/material/styles";
import Section from "../../components/Section";
import Interest from "./components/Interest";
import { ReactComponent as Badminton } from "assets/images/Badminton.svg";
import { Box } from "@mui/system";
import { ICON_SIZE } from "./constants";
const Interests = () => {
  const theme = useTheme();
  return (
    <Section title={"INTERESTS"}>
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        marginRight: "-2rem",
        marginBottom: "-1rem",
        justifyContent: "center"
      }}>
        <Interest
          icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
          interest={"Badminton"}
        />
        <Interest
          icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
          interest={"Badminton"}
        />
        <Interest
          icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
          interest={"Badminton"}
        />
        <Interest
          icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
          interest={"Badminton"}
        />
        <Interest
          icon={<Badminton fill={theme.palette.primary.main} width={ICON_SIZE} height={ICON_SIZE} />}
          interest={"Badminton"}
        />
      </Box>
    </Section>
  );
};

export default Interests;