import { Box } from "@mui/system";
import React from "react";

const IconWithCircleBackground = ({ icon, color }) => {
  return (
    <Box sx={{
      position: "relative",
      background: color,
      borderRadius: "100%",
      minWidth: "50px",
      height: "50px"
    }}>
      <Box sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
      }}>
        {icon}
      </Box>
    </Box>
  );
};

export default IconWithCircleBackground;