/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
import Content from "./Content.js";

const WorkExperience = () => {
  return (
    <>
      <Box className="work-experience">
        <NameContentHeader text="Work experience" />
        <Content title={"RFID solution"} listContent={listContent} />
      </Box >

    </>
  );
};
export default WorkExperience;

const listContent = [
  {
    year: "2019 - 2023", title: "RFID solution counselor at Beetech Co., Lft"
  }
];