/* eslint-disable max-len */
import { Typography } from "@mui/material";
import React from "react";

const Description = () => {
  return (
    <Typography variant="content" component={"li"}>
      Working environment is a real estate brokerage company, I have learned a lot about customer psychology and behavior from which to come up with appropriate strategies to close the deal as quickly as possible.
    </Typography>
  );
};

export default Description;