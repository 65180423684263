/* eslint-disable max-len */
import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js";
// import Content from "./Content.js";
import TimelineTree from "./TimelineTree.js";
import icon from "assets/images/icons8-highlight-50.png";

const WorkExperience = ({ background }) => {
  return (
    <>
      <Box className="work-experience" style={{ background: background }}>
        <NameContentHeader text="Work experience" icon={icon} />
        {/* <Content listContent={listContent} /> */}
        <TimelineTree />

      </Box >

    </>
  );
};
export default WorkExperience;

// const listContent = [
//   {
//     year: "2009–20011", title: "Sale Engineer and Sale Manager • BMT Contruction JSC"
//   },
//   {
//     year: "2010–2019", title: "CEO • BMT North Contruction JSC",
//     content: ["Made the first 5 highways in Vietnam."]
//   },
//   {
//     year: "2019–now", title: "CEO• Lagom Viet Nam",
//     content: ["Collecting paper, plastic, aluminum food containers at nearly 6000 schools, shops, factories, supermarkets to create recycled products; by 2030 to recycle 12,000 tons of plastic waste, reduce deforestation by 30 hectares per year.",
//       "Recycling asphalt pavement from reclaimed asphalt pavement and with plastic bags ; capacity 200,000 tons per year, reduce 100,000 tons of aggregate, 10,000 tons of asphalt, 1000 tons of plastic waste and 3200 tons CO2-eq."]
//   },
//   {
//     year: "2020–now", title: "Teacher • High school",
//     content: `Life skills teacher: Education changes the community's perception
//     of a balanced lifestyle: consumption and renewable, modern and
//     nature, outside and inside, individual and society`
//   }
// ];