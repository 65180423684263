import { Box, Typography } from "@mui/material";
import React from "react";
import LogoHaoHanh from "assets/images/business_HaoHanh/Logo.png";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import Avatar from "assets/images/business_HaoHanh/Avatar.jpg";

const contactStyle = {
  display: "block",
  textDecoration: "none",
  fontSize: "1em",
  color: "#fff",
  textAlign: "center",
};

const Cover = () => {
  return (
    <Box className="cover">
      <Box margin={"1rem"}><img className="logo" src={LogoHaoHanh} style={{ width: "5em" }} /></Box>
      <Box className="quadrangle title">
        <Box className="quadrangle title title-border" />
      </Box>
      <Box className="company-name-container">
        <Typography component={"span"} className="corporate">CORPORATE</Typography>
        <Typography component={"span"} className="company-name">HAO HANH</Typography>
      </Box>
      <Box className="quadrangle avatar"
        sx={{ backgroundImage: `url(${Avatar})`, backgroundPosition: "center" }}
      >
        <Box className="reverse">

        </Box>
      </Box>
      <Box className="quadrangle avatar avatar--after" />
      <Box className="contact-container">
        <Box className="quadrangle" />
        <Box className="contact">
          <Typography variant="h2" component={"div"} sx={{
            display: "flex", flexDirection: "column", justifyContent: "center",
          }}>
            <LocalPhoneOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff",
              marginBottom: "1rem"
            }} />
            <EmailOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff",
              marginBottom: "1rem"
            }} />
            <LanguageOutlinedIcon sx={{
              width: "0.6em",
              height: "0.6em",
              fontSize: "0.6em",
              color: "#fff"
            }} />
          </Typography>
          <Box marginLeft={"1rem"}>
            <Typography component={"a"} href="tel:0933204868"
              sx={contactStyle}
              mb={"1rem"}>
              093 320 48 68</Typography>
            <Typography component={"a"} href="mailto:haohanhrd@gmail.com"
              sx={contactStyle}
              mb={"1rem"}>
              haohanhrd@gmail.com</Typography>
            <Typography
              sx={contactStyle}
              component={"a"}
              href="https://www.haohanh.com.vn"
            >
              www.haohanh.com.vn</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="quadrangle quadrangle--extra" />
    </Box>
  );
};

export default Cover;
