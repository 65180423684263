import { Box } from "@mui/system";
import React from "react";
import { coverStyles } from "assets/js/personal-template3";
import Logo from "./Logo";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const MainInfo = () => {
  const theme = useTheme();
  const styles = coverStyles({ palette: theme.palette });
  return (
    <Box sx={styles.mainInfoContainer}>
      <Logo />
      <Box sx={styles.avatarCointainer}>
        <Box sx={styles.avatarHoDucVinh}></Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} marginTop={"1rem"}>
        <Typography variant="h1" textAlign={"center"}>Hồ Đức Vĩnh</Typography>

        <Typography variant="h2" marginTop={"8px"} textAlign={"center"}>
          Nhà sáng lập Diamond Entertainment
        </Typography>
        <Typography variant="h2" marginTop={"8px"} textAlign={"center"}>
          Nhà sáng lập đội bóng FC Nghệ Sỹ
        </Typography>
        <Typography variant="h2" marginTop={"8px"} textAlign={"center"}>
          Siêu mẫu
        </Typography>

      </Box>
    </Box>
  );
};

export default MainInfo;