/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

// import activity2 from "assets/images/business_HaoHanh/actitvity/activity2.png";
import SwipperItem from "./SwipperItem";
const items = [
  {
    title: "Bel Vietnam Company Limited",
    description: "Scope: Beetech provided ‘factory intruder prevention system’",
    img: "https://beetech.com.vn/wp/wp-content/uploads/2021/02/bel-01.jpg"
  },
  {
    // eslint-disable-next-line quotes
    title: `Ben Thanh Craven Tobacco Factory "A" under Saigon Industry Corporation`,
    description: "We provides the goods management system (export, import, inventory) of raw materials at packaging warehouses, yarn warehouses, and finishing product warehouses to apply RFID technology.",
    img: "https://beetech.com.vn/wp/wp-content/uploads/2020/11/Craven_A-300x200.jpg"
  },
];
export default function SwiperActivity() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item.title} description={item.description} image={item.img} />
        </SwiperSlide>;
      })}
    </Swiper>
  );
}