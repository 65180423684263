import { Box } from "@mui/system";
import NameContentHeader from "../NameContentHeader.js.js";
import ExpertiseBar from "./ExpertiseBar.js";

const Expertise = () => {
  return (
    <>
      <Box className="expertise">
        <NameContentHeader text="Expertise" />

        <Box className="expertise-box">
          <Box className="expertise-content">
            <p>Figma</p>
            <p>Illustrator</p>
            <p>HTML</p>
            <p>CSS</p>

          </Box>

          <Box className="expertise-bar">
            <ExpertiseBar value={80} />
            <ExpertiseBar value={80} />
            <ExpertiseBar value={80} />
            <ExpertiseBar value={80} />
          </Box>
        </Box>
      </Box >
    </>
  );
};
export default Expertise;