//

import paper from "./Paper";
import input from "./Input";
import button from "./Button";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    paper(theme),
    input(theme),
    button(theme)
  );
}
