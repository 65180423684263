/* eslint-disable max-len */
import React from "react";
import Section from "../../components/Section";
import ContentText from "../../components/text/ContentText";

const HightLight = () => {
  return (
    <Section title={"HIGHTLIGHT"}>
      <ContentText>High-quality, safe products, attentive service, and credibility have been trusted by foreign partners.</ContentText>
    </Section>
  );
};

export default HightLight;