/* eslint-disable max-len */
import React from "react";
import MemberItem from "./components/MemberItem";
import Section from "../../components/Section";

const Team = () => {
  return (
    <Section title={"Meet The Team"}>
      <MemberItem
        marginBottomBase
        name={"Name here"}
        jobTitle={"Job title"}
        introduction={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MemberItem
        marginBottomBase
        name={"Name here"}
        jobTitle={"Job title"}
        introduction={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
      <MemberItem
        name={"Name here"}
        jobTitle={"Job title"}
        introduction={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
      />
    </Section>
  );
};

export default Team;