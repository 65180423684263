import { Box } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ItemContact from "./ItemContact";

const Contact = () => {
  return (
    <>
      <Box className="contact">
        <Box className="contact-item">
          <ItemContact
            icon={<MailOutlineIcon />}
            content="a.nguyen@lorem.com.vn"
          />
        </Box>
        <Box className="contact-item">
          <ItemContact
            icon={<PhoneIcon />}
            content="03498213671 | 0325698714"
          />
        </Box>
        <Box className="contact-item">
          <ItemContact
            icon={<LocationOnIcon />}
            content="273 Đ. An Đường Vương, Phường 3, Quận 5, Thành phố Hồ Chí Minh"
          />
        </Box>
      </Box>
    </>
  );
};
export default Contact;