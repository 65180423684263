import { Box } from "@mui/system";

const ItemInterest = ({ icon, contentIcon }) => {
  return (
    <>
      <Box className="interest-item">
        <div className="interest-icon">{icon}</div>

        {contentIcon}
      </Box>
    </>
  );
};
export default ItemInterest;