import React from "react";
import HeaderAvartar from "./components/HeaderAvartar";
import { ContentHeader } from "./components/ContentHeader";
import { Box } from "@mui/material";
import Contact from "./components/Contact";
import Introduce from "./components/Introduce";
import "assets/scss/persional-template1.scss";
import Education from "./components/Education";
import Expertise from "./components/Expertise";
import WorkExperience from "./components/WorkExperience";
import Interests from "./components/Interests";

const Template1 = () => {
  return (
    <Box className="persional-template1" >
      < HeaderAvartar />
      <ContentHeader />
      <Contact />
      <Introduce />
      <Education />
      <Expertise />
      <WorkExperience />
      <Interests />

    </Box>
  );
};

export default Template1;