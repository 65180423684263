import SwipeActivity from "./SwipeActiviry.js";
import Section from "../../components/Section.js";
// import Content from "./Content.js";

const Activity = () => {
  return (
    <Section title={"ACTIVITY"}>
      <SwipeActivity />
    </Section>
  );
};
export default Activity;