import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Cover from "./section/cover";
import About from "./section/about";
import CompanyHistory from "./section/companyHistory";
import Mission from "./section/mission";
import Vision from "./section/vision";
import Service from "./section/service";
import WhyChooseUs from "./section/whyChooseUs";
import Activity from "./section/activity";
import HightLight from "./section/hightlight";
import Video from "./section/video";
import SaveContactButton from "pages/profile/components/SaveContactButton";
import Avatar from "assets/images/business_HaoHanh/Avatar.jpg";
import { convertImageUrlToBase64 } from "utils/Image";
import "assets/scss/business-template2-beetech.scss";

const Template2Beetech = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f4cf55"
      }
    }
  });
  const [imageToSaveContact, setImageToSaveContact] = useState("");
  document.documentElement.style.setProperty(
    "--bussiness-template-2-primary-color",
    // eslint-disable-next-line max-len
    "linear-gradient(180deg, #0277BD 2.28%, #039BE5 21.79%, #4FC3F7 35.78%, #00B0FF 45.11%, #0288D1 54.44%, #01579B 70.13%, #01579B 83.7%)");
  document.documentElement.style.setProperty(
    "--bussiness-template-2-primary-simple-color",
    "#f4cf55");

  useEffect(() => {
    const getImageToSaveContact = async () => {
      const imageBase64 = await convertImageUrlToBase64(Avatar);
      setImageToSaveContact(imageBase64);
    };
    getImageToSaveContact();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="business-template2">
        <Cover />
        <Box sx={{
          width: "100vw",
          minHeight: "100vh",
          padding: "24px",
          marginTop: "-1px",
          background: "#fff"
        }}
        >
          <HightLight />
          <Activity />
          <Video />
          <About />
          <WhyChooseUs />
          <CompanyHistory />
          <Mission />
          <Vision />
          <Service />
          <SaveContactButton contact={{
            name: "Beetech",
            email: "info@beetech.com.vn",
            phone: "+84 (028) 66529295",
            url: "https://beetech.com.vn",
            image: imageToSaveContact
          }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Template2Beetech;
