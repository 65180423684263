/* eslint-disable max-len */
import React from "react";
import { Box } from "@mui/material";
import Section from "../../components/Section";
import Content from "../../components/Quote";

const Vision = () => {
  return (
    <Box>
      <Section title={"VISION"}>
        <Content content={"To bring agricultural products to major supermarkets through official channels by 2027 and become partners of Western supermarket chains."} />
        <Content content={"To bring agricultural products to major supermarkets through official channels by 2027 and become partners of Western supermarket chains."} />
      </Section>

    </Box>
  );
};

export default Vision;