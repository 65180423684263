import { Box } from "@mui/system";

const NameContentHeader = ({ text }) => {

  return (
    <>
      <Box classname="name-content-header">
        {text}
        <div className="bar-under-name"></div>
      </Box>
    </>
  );
};

export default NameContentHeader;