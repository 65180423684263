/* eslint-disable max-len */
import React from "react";
import hexagonStyles from "assets/css/bussiness/template1/hexagon.module.css";
import subBackgroundStyles from "assets/css/bussiness/template1/hexagonSubBackground.module.css";
import { Box } from "@mui/material";
const SubBackground = () => {
  return (
    <Box sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      zIndex: "100"
    }}>
      <div className={`${hexagonStyles["r-hex"]} ${subBackgroundStyles["r-hex"]} ${subBackgroundStyles["sub-background"]}`}>
        <div className={`${hexagonStyles["r-hex-inner"]} 
      ${subBackgroundStyles["r-hex-inner"]} 
      ${subBackgroundStyles["sub-background"]}`}></div>
      </div>
    </Box>
  );
};

export default SubBackground;