import { Box } from "@mui/material";
import React from "react";

const LineDivider = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{
        height: "2px",
        background: "var(--primary-color)",
        width: "80%",
        margin: "0.8rem 0"
      }} />
    </Box>
  );
};

export default LineDivider;