/* eslint-disable max-len */
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import activity1 from "assets/images/business_HaoHanh/actitvity/activity1.jpg";
import activity2 from "assets/images/business_HaoHanh/actitvity/activity2.png";
import actitvity3 from "assets/images/business_HaoHanh/V1-fotor-20240328143239.jpg";
import SwipperItem from "./SwipperItem";

const items = [
  {
    title: "Region for cultivating dragon fruit meeting EU cleanliness standards.",
    description: "The current area is 40 hectares and is expected to expand to 120 hectares by the end of this year.",
    img: actitvity3
  },
  {
    title: "Dragon Fruit GLOBALG.A.P. for export to the EU",
    description: "Hao Hanh Company is proud to be a pioneer in the preservation and export of fresh dragon fruit that meets GLOBALG.A.P. standards by sea to the demanding European market",
    img: activity2
  },
  {
    title: "Ensuring Quality and Timely Delivery for Your Food Products",
    description: "Hao Hanh Company has a manufacturing and packaging plant that meets the HACCP and FDA standards of the United States. Depending on the order, we can transport the goods by sea or air to meet the time and requirements of customers.",
    img: activity1
  },
];
export default function SwiperActivity() {
  return (
    <Swiper
      loop={true}
      modules={[Scrollbar]}
      slidesPerView={1}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => {
        return <SwiperSlide>
          <SwipperItem title={item.title} description={item.description} image={item.img} />
        </SwiperSlide>;
      })}
    </Swiper>
  );
}