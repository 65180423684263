import axios from "axios";
import { Buffer } from "buffer";
export const convertImageUrlToBase64 = async (url) => {
  const response = await axios.get(url, { responseType: "arraybuffer" });
  if (response?.data) {
    const base64Image = Buffer.from(response.data, "binary").toString("base64");
    return base64Image;
  }
  return "";
};
export const convertImageFileToBase64 = (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};