import React from "react";
import Section from "../../components/Section";
import Language from "./components/Language";

const Languages = () => {
  return (
    <Section title={"LANGUAGES"}>
      <Language language={"VIETNAMESE"} description={"Mother tongue"}/>
      <Language language={"ENGLISH"} description={"Advanced level"}/>
    </Section>
  );
};

export default Languages;