/* eslint-disable max-len */
import { ICON_SIZE } from "./constants";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import AutorenewIcon from "@mui/icons-material/Autorenew";
export const DataService = [
  {
    icon: <AgricultureIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "Agriculture",
    context: [
      "Planting various types of organic farming products that meet the standard.",
      "Provides and distributes organic, mineral, and microbiological fertilizers... directly imported from factories in the USA, Canada, Japan, and the Netherlands with all OMI and IFOAM certifications for growing clean vegetables."
    ],
  },
  {
    icon: <AutorenewIcon width={ICON_SIZE} height={ICON_SIZE} />,
    title: "Processing",
    context: [
      "Dries various types of fruits using cold technology to achieve high quality, retain the flavor, nutritional components, and vitamins of fruits that are good for everyone's health.",
      "Processes, packages, and preserves various types of fresh fruits using biotechnology for export to all countries in the world."
    ],
  },
];
