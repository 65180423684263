import { Paper, Typography, Box } from "@mui/material";
import React from "react";

const SwipperItem = ({ title, description, image }) => {
  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          bgcolor: "background.default",
        }}
      >
        <Typography fontWeight={"bold"}>{title}</Typography>
      </Paper>

      <Box sx={{ height: "auto", width: "100%", background: "white", padding: "10px 10px", textTransform: "none" }}>
        <img style={{ height: "auto", width: "100%", background: "white" }} src={image} />
        {description}
      </Box></>
  );
};

export default SwipperItem;