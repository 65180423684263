/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import React from "react";
import HexagonAvatar from "./components/HexagonAvatar";
import Logo from "./components/Logo";
const Cover = () => {
  return (
    <Box sx={{
      height: "100vh",
      width: "100vw",
      position: "relative",
      overflow: "hidden",
      background: "#fff"
    }}>
      <Logo />
      <HexagonAvatar />
    </Box>
  );
};

export default Cover;