import React from "react";
import LogoDiamond from "assets/images/HoDucVinh/Diamond-preview.png";
import { Box } from "@mui/system";

const Logo = () => {
  return (
    <Box margin={"0.2rem 0 0 0"}>
      <img src={LogoDiamond} style={{ height: "15vw", width: "auto" }} />
    </Box>
  );
};

export default Logo;