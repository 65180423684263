import { Paper, Typography } from "@mui/material";
import React from "react";

const SwipperItem = ({ title, image }) => {
  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          border: "10px",
          boxShadow: "2px black",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
        }}
      >
        <Typography margin="0 auto" fontWeight={"bold"}>{title}</Typography>
      </Paper>

      <img style={{ height: "auto", width: "100%", background: "white" }} src={image} />
    </>
  );
};

export default SwipperItem;