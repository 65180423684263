/* eslint-disable max-len */
import React from "react";
// import ContentText from "../../components/text/ContentText";
import Section from "../../components/Section";
import ContentText from "../../components/text/ContentText";
const WhyChooseUs = () => {
  return (
    <Section title={"WHY CHOOSE US"}>
      <ContentText>
        We are a company that operates with dedication and integrity to all customers, both domestic and international, with the motto of win-win for both parties.
      </ContentText>
    </Section>
  );
};

export default WhyChooseUs;