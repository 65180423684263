import { Box } from "@mui/system";

const NameContentHeader = ({ text }) => {
  return (
    <>
      <Box className="name-content-header-2">
        {text}
      </Box>
    </>
  );
};

export default NameContentHeader;