import { Box } from "@mui/material";
import React from "react";
import TitleText from "./text/TitleText";
import ContentText from "./text/ContentText";
const ListItemWithIcon = ({ title, content, icon, marginBottomBase, ...props }) => {
  return (
    <Box display={"flex"} alignItems={"center"} marginBottom={marginBottomBase && "24px"} {...props}>
      {icon}
      <Box marginLeft={"1rem"}>
        <TitleText >{title}</TitleText>
        <ContentText marginTop={"0.6rem"}>{content}</ContentText>
      </Box>
    </Box>
  );
};

export default ListItemWithIcon;