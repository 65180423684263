import { Box } from "@mui/system";
import Circular from "assets/images/CircularHeaderPersonal2.png";
import Avatar from "assets/images/PROFILE_PIC.png";
import BoxInfoHeader from "./BoxInfoHeader";

const BoxImageCenterHeader = () => {
  return (
    <>
      <Box style={{ zIndex: 1000 }}>
        <Box className="avatar-header" >
          <img src={Avatar} alt="Avatar" />

          < BoxInfoHeader />
        </Box>
        <img className="avatar-header" style={{ transform: "translate(-50%, 10%)" }} src={Circular} alt="Avatar" />

      </Box>

    </>
  );
};
export default BoxImageCenterHeader;