import NameContentHeader from "../NameContentHeader";
import { Box } from "@mui/system";
import Data from "./Content";

const Contents = [
  { 
    year: "2016 - AUJOURD'HUI",
    content: ["ILIAD", "Technicien en télécommunications"]
  },
  { 
    year: "2013 - 2015",
    content: ["REXEL Référent Domotique"]
  }
];

const Experience = () => {
  return (
    <Box className="work-experiences">
      <NameContentHeader text="Work Experiences" />
      <Data listContent={Contents} />
    </Box>
  );
};

export default Experience;
