import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { GRID_LEFT, GRID_RIGHT, ICON_SIZE } from "./constants";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as FacebookIcon } from "assets/images/Facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/images/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/Linkedin.svg";
import { ReactComponent as TikTokIcon } from "assets/images/Tiktok.svg";

const Contact = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          flex: 1,
          color: "black"
        }}
      >

        <Grid container spacing={1} display={"flex"} style={{ margin: "0 auto" }} width={"80%"} >
          <Grid item xs={GRID_LEFT}>
            <LocalPhoneOutlinedIcon color="primary" />
          </Grid>
          <Grid item xs={GRID_RIGHT}>
            <Typography variant="h6" color={theme.palette.primary.main} textAlign={"center"}>
              +84 911 123 155</Typography>
          </Grid>
          <Grid item xs={GRID_LEFT}>
            <LanguageOutlinedIcon color="primary" />
          </Grid>
          <Grid item xs={GRID_RIGHT}>
            <Typography variant="h6" color={theme.palette.primary.main} textAlign={"center"}>
              beetech.com.vn</Typography>
          </Grid>

          <Grid item xs={GRID_LEFT}>
            <EmailOutlinedIcon color="primary" />
          </Grid>
          <Grid item xs={GRID_RIGHT}>
            <Typography variant="h6" color={theme.palette.primary.main} textAlign={"center"}>
              @beetech.com.vn </Typography>
          </Grid>

        </Grid>
        <Box width={"90%"} style={{ margin: "0 auto", textAlign: "center" }}>
          <Typography variant="h2" color={theme.palette.primary.main} textAlign={"center"}
          >
            30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc City, HoChiMinh. </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} margin={"1rem"}>
          <Box marginRight={"1rem"}>
            <FacebookIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
          </Box>
          <Box marginRight={"1rem"}>
            <InstagramIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
          </Box>
          <Box marginRight={"1rem"}>
            <LinkedinIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
          </Box>
          <Box marginRight={"1rem"}>
            <TikTokIcon width={ICON_SIZE} height={ICON_SIZE} fill={theme.palette.primary.main} />
          </Box>
        </Box>
        <Typography variant="h2"
          color={theme.palette.primary.main}
          textAlign={"center"}
          margin={"1rem"}
          fontWeight={"bold"}
          textTransform={"uppercase"}
        >
          @beetech
        </Typography>
      </Box>
    </>
  );
};
export default Contact;