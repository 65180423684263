/* eslint-disable max-len */
import React from "react";
// import ContentText from "../../components/text/ContentText";
import Section from "../../components/Section";
import { Box } from "@mui/material";
import CheckListItem from "../../components/CheckListItem";

const About = () => {
  return (
    <Section title={"ABOUT COMPANY"}>
      {/* <ContentText>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </ContentText> */}
      <Box marginTop={"1rem"}>
        <CheckListItem content={"Bee Tech was the first company in Vietnam to work on RFID. Specializing in consulting and providing leading technology solutions (AutoID; IoT, Al, and PL) in Southeast Asia."} />
        <CheckListItem content={"We want to change the Vietnamese people’s mindset in making technology is."} />
        <CheckListItem content={"We have been affirming our pioneering role in RFID solutions in Vietnam."} />
        <CheckListItem content={"In these years, we have grown steadily, strong, and have built for ourselves an important place for business partners. Recognized by the market as the top provider of reliable solutions (especially affirms our pioneering role in RFID solutions in Vietnam)."} />
      </Box>
    </Section>
  );
};

export default About;