import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const LineDivider = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "80%" }}>
      <Box sx={{
        height: "2px",
        background: theme.palette.primary.main,
        width: "80%",
        margin: "0.8rem 0"
      }} />
    </Box>
  );
};

export default LineDivider;